import { isCustomer, isDriver, isSuperadmin } from './check-user-level'
import { tripInfo } from './get-trip-info'
import { formatToHTMLinput } from './handle-date'
import handleErr from './handle-error'
import isEmpty from './is-empty'

const validateEmail = (text) => text?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

// const creatableFilterConfig = { //default
//   ignoreCase: true,
//   ignoreAccents: true,
//   trim: true,
//   // matchFrom: matchFromStart ? ('start' as const) : ('any' as const),
// }

const isDelivery = (trip) => trip?.tripType === 'delivery'
const isRide = (trip) => trip?.tripType === 'ride'

const isPending = (trip) => {
  try {
    return trip?.tripState === 'pending'
  } catch (err) {
    handleErr({ err })
    return false
  }
}
const isEnded = (trip) => {
  try {
    return trip?.tripState === 'ended'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isCanceled = (trip) => {
  try {
    return trip?.tripState === 'canceled'
  } catch (err) {
    handleErr({ err })
  }
}

const getActiveDrivers = ({ users, includeDeactivated }) => {
  try {
    return users?.length
      ? users
          .filter((u) => isDriver(u))
          .filter((d) => (includeDeactivated ? true : !d.isDeactivated))
          .sort(sortByFirstName)
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const getActiveCustomers = ({ users, includeDeactivated }) => {
  try {
    return users?.length
      ? users
          .filter((u) => isCustomer(u))
          .filter((d) => (includeDeactivated ? true : !d.isDeactivated))
          .sort(sortByFirstName)
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const getActiveVehicles = ({ vehicles, includeDeactivated }) => {
  try {
    return vehicles?.length
      ? vehicles.filter((d) => (includeDeactivated ? true : !d.isDeactivated)).sort(sortByName)
      : []
  } catch (err) {
    handleErr({ err })
  }
}

const isFound = ({ list, id, byKey = '_id' }) => {
  try {
    return list?.length ? list.find((el) => el && el[byKey] === id) : ''
  } catch (err) {
    handleErr({ err })
  }
}

const getIsDriving = ({ trips, driver }) => trips?.some((t) => t?.onTrip && t.driver === driver)
const getTripsPendingByDriver = ({ trips, driver }) =>
  trips?.filter((t) => isPending(t) && t.driver === driver)

const getFullTripDist = ({ trip = {}, idx }) => {
  try {
    const { destinations = [], driverLocations = [] } = trip
    return destinations?.map((dest, i) => {
      return driverLocations?.reduce((acc, dl) => {
        return dl.distance &&
          dl?.locationType?.replace(/^\D+/g, '') &&
          Number(dl?.locationType?.replace(/^\D+/g, '')) === (idx >= 0 ? idx : i) + 1
          ? acc + (dl.distance || 0)
          : acc
      }, 0)
    })
  } catch (err) {
    handleErr({ err })
  }
}

const getPkDpDist = ({ trip = {}, idx }) => {
  try {
    return trip.driverLocations?.find(
      (dl) => dl?.locationType === `isDropoff${!idx || idx === 0 ? 1 : idx}`,
    )?.distance
  } catch (err) {
    handleErr({ err })
  }
}

const currentDriver = ({ trip, idx, users }) => {
  return users?.find(
    (u) =>
      u?._id ===
        trip?.driverHistory?.find(
          (d) =>
            formatToHTMLinput({ dateData: new Date(d?.date) }) >=
              (trip?.times[`startTime${idx >= 0 ? idx : trip?.idx}`] ||
                trip?.times[`pickupTime${idx >= 0 ? idx : trip?.idx}`]) &&
            formatToHTMLinput({ dateData: new Date(d?.date) }) <=
              trip?.times[`dropoffTime${idx >= 0 ? idx : trip?.idx}`],
        )?._id ||
      (trip?.driverHistory?.length === 1 && trip?.driverHistory?.find((d) => d?._id === u?._id)) ||
      u?._id === trip?.driver,
  )
}

const sortByPkTime = (a, b) => {
  try {
    const tripA = a?.t1Info || a?.t2Info || a
    const tripB = b?.t1Info || b?.t2Info || b
    const {
      destIdxToShow: idxA,
      isStart: isStartA,
      // isArrive: isArriveA,
      // isPickup: isPickupA,
      // isDropoff: isDropoffA,
    } = tripInfo({ trip: tripA })
    const {
      destIdxToShow: idxB,
      // isStart: isStartB,
      // isArrive: isArriveB,
      // isPickup: isPickupB,
      // isDropoff: isDropoffB,
    } = tripInfo({ trip: tripB })
    const tA =
      tripA?.times[`expectedStartTime${(idxA || 0) + 1}`] ||
      tripA?.times[`expectedPickupTime${(idxA || 0) + 1}`]
    const tB =
      tripB?.times[`expectedStartTime${(idxB || 0) + 1}`] ||
      tripB?.times[`expectedPickupTime${(idxB || 0) + 1}`]
    // return isArriveA ||
    //   isArriveB ||
    //   isPickupA ||
    //   isPickupB ||
    //   isDropoffA ||
    //   isDropoffB ||
    //   !tA?.customerReady ||
    //   !tB?.customerReady
    //   ? 0
    return isStartA ? Date.parse(tA) - Date.parse(tB) : 0
  } catch (err) {
    handleErr({ err })
  }
}

const latestStartStopTime = ({ trip = {} }) => {
  try {
    const { timeIdx = 1, times = {} } = tripInfo({ trip }) || {}
    const lTime =
      times[`pickupTime${timeIdx}`] ||
      times[`arriveTime${timeIdx}`] ||
      times[`startTime${timeIdx}`] ||
      times[`expectedStartTime${timeIdx}`] ||
      times[`expectedPickupTime${timeIdx}`] ||
      times[`expectedDropoffTime${timeIdx}`] ||
      times[`expectedStartTime${timeIdx - 1}`] ||
      times[`expectedPickupTime${timeIdx - 1}`] ||
      times[`expectedDropoffTime${timeIdx - 1}`]
    return lTime
  } catch (err) {
    handleErr({ err })
  }
}

const closest = ({ goal = Date.parse(formatToHTMLinput({})), arr }) => {
  try {
    return arr.reduce((prev, curr) => {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
    })
  } catch (err) {
    handleErr({ err })
  }
}

const sortTripsByLatestTime = (a, b) => {
  try {
    const now = Date.parse(formatToHTMLinput({}))
    const tA = a?.t1Info || a
    const tB = b?.t1Info || b
    const timeA = Date.parse(
      formatToHTMLinput({
        dateData: new Date(latestStartStopTime({ trip: tA })),
      }),
    )
    const timeB = Date.parse(
      formatToHTMLinput({
        dateData: new Date(latestStartStopTime({ trip: tB })),
      }),
    )
    const closetsToNowA = timeA >= now && timeB < now && closest({ arr: [timeA, timeA] }) === timeA
    return !tA?.customerReady || !tB?.customerReady ? 0 : closetsToNowA ? -1 : timeA - timeB
  } catch (err) {
    handleErr({ err })
    return 0
  }
}
// const sortTripsByLatestTime = (a, b) => {
//   try {
//     const tA = a?.t1Info || a
//     const tB = b?.t1Info || b
//     const { destIdxToShow: destIdxToShowA } = tripInfo({ trip: tA })
//     const { destIdxToShow: destIdxToShowB } = tripInfo({ trip: tB })

//     return Date.parse(
//       formatToHTMLinput({
//         dateData: new Date(
//           tA.times[`pickupTime${destIdxToShowA + 1}`] ||
//             tA.times[`arriveTime${destIdxToShowA + 1}`] ||
//             tA.times[`startTime${destIdxToShowA + 1}`] ||
//             tA.times[`expectedStartTime${destIdxToShowA + 1}`] ||
//             tA.times[`expectedPickupTime${destIdxToShowA + 1}`] ||
//             tA.times[`expectedDropoffTime${destIdxToShowA + 1}`],
//         ),
//       }),
//     ) >
//       Date.parse(
//         formatToHTMLinput({
//           dateData: new Date(
//             tB.times[`pickupTime${destIdxToShowB + 1}`] ||
//               tB.times[`arriveTime${destIdxToShowB + 1}`] ||
//               tB.times[`startTime${destIdxToShowB + 1}`] ||
//               tB.times[`expectedStartTime${destIdxToShowB + 1}`] ||
//               tB.times[`expectedPickupTime${destIdxToShowB + 1}`] ||
//               tB.times[`expectedDropoffTime${destIdxToShowB + 1}`],
//           ),
//         }),
//       )
//       ? 0
//       : Date.parse(
//           formatToHTMLinput({
//             dateData: new Date(
//               tB.times[`pickupTime${destIdxToShowB + 1}`] ||
//                 tB.times[`arriveTime${destIdxToShowB + 1}`] ||
//                 tB.times[`startTime${destIdxToShowB + 1}`] ||
//                 tB.times[`expectedStartTime${destIdxToShowB + 1}`] ||
//                 tB.times[`expectedPickupTime${destIdxToShowB + 1}`] ||
//                 tB.times[`expectedDropoffTime${destIdxToShowB + 1}`],
//             ),
//           }),
//         )
//       ? -1
//       : 1
//   } catch (err) {
//     handleErr({ err })
//   }
// }

const sortByPriority = (a, b) => {
  try {
    const { priority: priorityA, routeIndex: routeIndexA } = a?.t1Info || a
    const { priority: priorityB, routeIndex: routeIndexB } = b?.t1Info || b
    // return priorityA === priorityB ? 0 : priorityA ? -1 : 1
    return (routeIndexA >= 0 && routeIndexB >= 0) || priorityA === priorityB
      ? 0
      : priorityA
      ? -1
      : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByOneDest = (a, b) => {
  try {
    const { destLen, isDropoff } = a?.t1Info || tripInfo({ trip: a })
    // const { destinations: destinationsB } = b?.t1Info || b
    // return priorityA === priorityB ? 0 : priorityA ? -1 : 1
    return destLen === 1 && isDropoff ? -1 : 0
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByOnTrip = (a, b) => {
  try {
    const { onTrip: onTripA } = a?.t1Info || a || {}
    const { onTrip: onTripB } = b?.t1Info || b || {}
    return (a.routeIndex >= 0 && b.routeIndex >= 0) || onTripA === onTripB ? 0 : onTripA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByArrive = (a, b) => {
  try {
    const { isArrive: isArriveA } = a?.t1Info || tripInfo({ trip: a })
    const { isArrive: isArriveB } = b?.t1Info || tripInfo({ trip: b })
    return isArriveA === isArriveB ? 0 : isArriveA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByNext = (a, b) => {
  try {
    const { isNext: isNextA } = a?.t1Info || a
    const { isNext: isNextB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return isNextA === isNextB ? 0 : isNextA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByCustomerReady = (a, b) => {
  try {
    const { customerReady: customerReadyA } = a?.t1Info || a
    const { customerReady: customerReadyB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return customerReadyA === customerReadyB ? 0 : customerReadyA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByDriver = (a, b) => {
  try {
    const { driver: driverA } = a?.t1Info || a
    const { driver: driverB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return driverA === driverB ? 0 : driverA ? -1 : 1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByEnded = (a, b) => {
  try {
    const { tripState: tripStateA } = a?.t1Info || a
    const { tripState: tripStateB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return tripStateA === tripStateB ? 0 : tripStateA === 'ended' ? 1 : -1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByCanceled = (a, b) => {
  try {
    const { tripState: tripStateA } = a?.t1Info || a
    const { tripState: tripStateB } = b?.t1Info || b
    // console.log(isNextA, isNextB)
    return tripStateA === tripStateB ? 0 : tripStateA === 'canceled' ? 1 : -1
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const nextDestDist = (a, b) => {
  try {
    const tInfoA = a?.t1Info || tripInfo({ trip: a })
    const tInfoB = b?.t1Info || tripInfo({ trip: b })
    const {
      isStart: isStartA,
      isArrive: isArriveA,
      isPickup: isPickupA,
      isDropoff: isDropoffA,
    } = tInfoA
    const {
      isStart: isStartB,
      isArrive: isArriveB,
      isPickup: isPickupB,
      isDropoff: isDropoffB,
    } = tInfoB
    const distA =
      isStartA || isArriveA
        ? a?.pkLocDist || a?.dist || a
        : isPickupA || isDropoffA
        ? a?.dpLocDist || a?.dist || a
        : {}
    const distB =
      isStartB || isArriveB
        ? b?.pkLocDist || b?.dist || b
        : isPickupB || isDropoffB
        ? b?.dpLocDist || b?.dist || b
        : {}

    const { meter: meterA = 0 } = distA
    const { meter: meterB = 0 } = distB
    return { meterA, meterB }
  } catch (err) {
    handleErr({ err })
    return {}
  }
}

const sortByDistance = (a, b) => {
  try {
    const { meterA, meterB } = nextDestDist(a, b)
    // const tInfoA = a?.t1Info || tripInfo({ trip: a })
    // const tInfoB = b?.t1Info || tripInfo({ trip: b })

    // const {
    //   isStart: isStartA,
    //   isArrive: isArriveA,
    //   isPickup: isPickupA,
    //   isDropoff: isDropoffA,
    // } = tInfoA
    // const {
    //   isStart: isStartB,
    //   isArrive: isArriveB,
    //   isPickup: isPickupB,
    //   isDropoff: isDropoffB,
    // } = tInfoB
    // const distA =
    //  isStartA // || isArriveA
    //   ? a?.pkLocDist || a?.dist || a
    //   : isPickupA || isDropoffA
    //   ? a?.dpLocDist || a?.dist || a
    //   : {}
    // const distB =
    // isStartB // || isArriveB
    //   ? b?.pkLocDist || b?.dist || b
    //   : isPickupB || isDropoffB
    //   ? b?.dpLocDist || b?.dist || b
    //   : {}

    // const { meter: meterA = 0 } = distA
    // const { meter: meterB = 0 } = distB
    // return isArriveA || isArriveB ? 0 : meterA - meterB
    return meterA - meterB
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByRouteIndex = (a, b) => {
  try {
    const { meterA, meterB } = nextDestDist(a, b)
    const {
      autoRouteIndex: aAutoRIdx,
      routeIndex: aRIdx,
      // isStart: isStartA,
      // isArrive: isArriveA,
      isPickup: isPickupA,
      // isDropoff: isDropoffA,
    } = a?.t1Info || a
    const {
      autoRouteIndex: bAutoRIdx,
      routeIndex: bRIdx,
      isStart: isStartB,
      // isArrive: isArriveB,
      // isPickup: isPickupB,
      // isDropoff: isDropoffB,
    } = b?.t1Info || b
    return aAutoRIdx >= 0 && bAutoRIdx >= 0
      ? aAutoRIdx - bAutoRIdx
      : aRIdx >= 0 && bRIdx >= 0
      ? isPickupA && isStartB && meterB < meterA
        ? 1
        : aRIdx - bRIdx
      : (aRIdx === -1 || !aRIdx) && (bRIdx === -1 || !bRIdx)
      ? 0
      : 0
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByName = (a, b) => {
  try {
    const nameA = a?.name || a
    const nameB = b?.name || b
    return nameA.localeCompare(nameB)
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const sortByFirstName = (a, b) => {
  try {
    return a?.firstName?.localeCompare(b?.firstName)
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const isGroup = ({ trip }) => {
  try {
    return trip?.times
      ? Object.entries(trip.times).some(([key, val]) => {
          const { timeIdx } = tripInfo({ trip })
          const keyIdx = key.replace(/^\D+/g, '') ? Number(key.replace(/^\D+/g, '')) : ''
          return key.indexOf('group') !== -1 && keyIdx === timeIdx
        })
      : false
  } catch (err) {
    handleErr({ err })
  }
}

const isNotGroupPickedUp = ({ trip }) => {
  const { timeIdx: timeIdxPendingQ, times: timesPendingQ = {} } = tripInfo({ trip })
  return Array.from(Object.keys(timesPendingQ))?.some((tTime) => {
    return (
      tTime?.indexOf(`groupPkTime${timeIdxPendingQ}`) === -1 &&
      !timesPendingQ[`groupPkTime${timeIdxPendingQ}`]
    )
  })
}

const getTripCust = ({ users, id }) => {
  try {
    return users?.length ? users.find((cust) => cust?._id === id) : ''
  } catch (err) {
    handleErr({ err })
  }
}

const getFullName = ({ user }) => {
  try {
    const { firstName, middleName, lastName } = user || {}
    return `${firstName || ''} ${middleName || ''} ${lastName || ''}`?.replace(/  +/g, ' ')?.trim()
  } catch (err) {
    handleErr({ err })
  }
}

const getNameInitials = ({ name }) => {
  try {
    if (!name) return ''
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
    const initials = [...name?.matchAll(rgx)] || []
    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
  } catch (err) {
    handleErr({ err })
  }
}

const shortName = ({ user, maxLen }) => {
  try {
    const { firstName, lastName } = user || {}
    const lastNameParts = lastName?.split(' ')
    const shortN = `${firstName || ''} ${
      lastNameParts?.length && lastNameParts[0] ? lastNameParts[0][0]?.toUpperCase() : ''
    }${
      lastNameParts?.length && lastNameParts[1] ? `${lastNameParts[1][0]?.toUpperCase()}.` : ''
    }`.trim()
    return !maxLen
      ? shortN
      : `${shortN?.substring(0, maxLen)?.trim() ? `${shortN?.substring(0, maxLen)?.trim()}.` : ''}`
  } catch (err) {
    handleErr({ err })
  }
}

const getPhone = ({ data }) => {
  try {
    const { cellPhone, homePhone, phone } = data || {}
    return cellPhone || homePhone || phone
  } catch (err) {
    handleErr({ err })
  }
}

const getFullAddress = ({ data, showCountry }) => {
  try {
    const { address, city, state, zipcode, country } = data || {}
    return `${address ? `${address},` : ''} ${city ? `${city},` : ''} ${state ? `${state},` : ''} ${
      zipcode ? `${zipcode}` : ''
    }${showCountry && country ? `, ${country}` : ''}`
      ?.replace(/  +/g, ' ')
      ?.trim()
  } catch (err) {
    handleErr({ err })
  }
}

const getTripDestination = ({ tripsDest, tripDestId }) => {
  try {
    return tripsDest?.length ? tripsDest.find((dest) => dest?._id === tripDestId) : ''
  } catch (err) {
    handleErr({ err })
  }
}

const linearDistance = ({ lat1, lng1, lat2, lng2 }) => {
  try {
    const deg2rad = (deg) => deg * (Math.PI / 180)
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(Number(lat2) - Number(lat1)) // deg2rad below
    const dLon = deg2rad(Number(lng2) - Number(lng1))
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(Number(lat1))) *
        Math.cos(deg2rad(Number(lat2))) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return {
      mile: Number((d / 1.609).toFixed(1)) || 0,
      km: Number(d.toFixed(1)) || 0,
      meter: Number((d * 1000).toFixed(1)) || 0,
    }
  } catch (err) {
    handleErr({ err })
  }
}

const isNearBy = ({ distance, meter = 150 }) =>
  distance?.meter === 0 || distance?.meter === '0' || distance.meter <= meter
    ? true
    : distance
    ? distance <= meter
    : false

// get objs different from arr1
const getRemainingItems = (arr1, arr2) => {
  try {
    return arr1?.filter((el) => !arr2?.find((element) => element._id === el._id))
  } catch (err) {
    handleErr({ err })
  }
}

const ObjOrdered = (unordered) => {
  try {
    const objReduced = (uo) =>
      Object.keys(uo)
        .sort()
        .reduce((obj, key) => {
          obj[key] = uo[key]
          return obj
        }, {})
    return !unordered ? '' : unordered?.length ? unordered.map(objReduced) : objReduced(unordered)
  } catch (err) {
    handleErr({ err })
  }
}

const arrUniq = (arr) => [...new Set(arr)]

const uniqueById = (arr) => {
  try {
    const map = new Map()
    return arr.filter((t) => {
      if (t?._id && map.get(t?._id)) return false
      t?._id && map.set(t._id, t._id)
      return t?._id ? true : false
    })
  } catch (err) {
    handleErr({ err })
    return arr
  }
}

const uniqueByKey = ({ arr, key }) => {
  try {
    const map = new Map()
    return arr?.filter((item) => {
      if (map.get(item[key])) return false
      map.set(item[key], item)
      return true
    })
  } catch (err) {
    handleErr({ err })
  }
}

const isScheduled = (trip) => {
  try {
    const { schedule } = trip || {}
    return schedule && schedule.length ? true : false
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isSameCustomerAndPk = ({ bookedTrip, trips }) => {
  try {
    return trips?.find((t) => {
      const { customer, times = {} } = t || {}
      const { expectedStartTime1: st1, expectedPickupTime1: pk1, expectedDropoffTime1: dp1 } = times
      const { customer: tripCustomer, times: tripTimes = {} } = bookedTrip || {}
      const {
        expectedStartTime1: st2,
        expectedPickupTime1: pk2,
        expectedDropoffTime1: dp2,
      } = tripTimes
      return (
        customer === tripCustomer &&
        Date.parse(pk1) === Date.parse(pk2) &&
        Date.parse(dp1) === Date.parse(dp2)
      )
    })
  } catch (err) {
    handleErr({ err })
  }
}

const sortRouteAI = ({ trips }) => {
  try {
    return trips
      .sort(sortByDistance)
      .sort(sortByPkTime)
      .sort(sortByPriority)
      .sort(sortByOneDest)
      .sort(sortByRouteIndex)
      .sort(sortByArrive)
  } catch (err) {
    handleErr({ err })
  }
}

const uniqueByCustomerAndPickup = (trips) => {
  try {
    const map = new Map()
    return sortRouteAI({
      trips: trips
        .sort((a, b) => (a.tripState === b.tripState ? 0 : a.tripState === 'pending' ? -1 : 1))
        .sort((a, b) => (isScheduled(a) === isScheduled(b) ? 0 : isScheduled(b) ? -1 : 1))
        ?.filter((trip) => {
          const { expectedStartTime1, expectedPickupTime1, expectedDropoffTime1 } = trip.times || {}
          const mapKey = `${String(
            trip.customer,
          )}_${expectedStartTime1}_${expectedPickupTime1}_${expectedDropoffTime1}`
          if (map.get(mapKey)) return false
          map.set(mapKey, trip)
          return true
        }),
    })
  } catch (err) {
    handleErr({ err })
  }
}

const groupBy = (objectArray, property) => {
  try {
    return objectArray.reduce((r, a) => {
      r[a[property]] = r[a[property]] || []
      r[a[property]].push(a)
      return r
    }, Object.create(null))
  } catch (err) {
    handleErr({ err })
  }
}

const areEquals = (a = {}, b = {}) => {
  try {
    return (
      a &&
      b &&
      (ObjOrdered(a) === ObjOrdered(b) ||
        JSON.stringify(ObjOrdered(a)) === JSON.stringify(ObjOrdered(b)))
    )
  } catch (err) {
    handleErr({ err })
  }
}

const docEl = ({ id, elClass, tagName, all }) => {
  try {
    return id
      ? document.getElementById(id)
      : elClass && all
      ? document.querySelectorAll(`.${elClass}`)
      : elClass
      ? document.querySelector(`.${elClass}`)
      : tagName && all
      ? document.getElementsByTagName(`${tagName}`)
      : tagName
      ? document.getElementsByTagName(`${tagName}`)[0]
      : ''
  } catch (err) {
    handleErr({ err })
  }
}

const calcOilLife = ({ oilLife, odo1, odo2 }) => {
  try {
    const milesDiff = +odo2 > +odo1 ? +odo2 - +odo1 : ''
    return milesDiff ? oilLife - milesDiff : oilLife
  } catch (err) {
    handleErr({ err })
  }
}

const userIsTripDriver = ({ trip, id }) => {
  try {
    const { driver, driverHistory } = trip || {}
    return (
      (isPending(trip) && String(id) === String(driver)) ||
      (!isPending(trip) && driverHistory?.some((d) => d?._id === id))
    )
  } catch (err) {
    handleErr({ err })
  }
}

const debounce = (fn, delay) => {
  const myObj = {}
  return function (...args) {
    if (myObj.timerId) {
      clearTimeout(myObj.timerId)
    }
    myObj.timerId = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const onlyDestinations = (dests) => {
  try {
    return dests?.length
      ? dests
          .filter((d) => !d?.level) //no customers
          .sort(sortByName)
          .sort((a, b) => (a.isDeactivated === b.isDeactivated ? 0 : b.isDeactivated ? -1 : 1))
      : ''
  } catch (err) {
    handleErr({ err })
  }
}

const getCompanyName = ({ list, compId }) => {
  try {
    return list?.find((c) => c?._id === compId)?.name
  } catch (err) {
    handleErr({ err })
  }
}

const validVin = (vin) => {
  const re = new RegExp('^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$')
  return vin.match(re)
}

const uInfo = (userInfo) => {
  try {
    return {
      _id: userInfo?._id,
      company: userInfo?.company?._id || userInfo?.company,
      level: userInfo?.level,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      facility: userInfo?.facility,
    }
  } catch (err) {
    handleErr({ err })
  }
}

const usersByLevel = ({ level, users = [], all }) => {
  try {
    return users.filter((u) => u?.level === level)?.filter((u) => (all ? true : !u?.isDeactivated))
  } catch (err) {
    handleErr({ err })
    return []
  }
}

const isDisability = (customerOrVehicleData) => {
  try {
    const { features = [], level } = customerOrVehicleData || {}
    const hasDisability = features?.length
    const wheelchair = features.some((f) => f?.toLowerCase() === 'wheelchair')
    const bariatricWheelchair = features.some((f) => f === 'bariatricWheelchair')
    const stretcher = features.some((f) => f?.toLowerCase() === 'stretcher')
    const bariatricStretcher = features.some((f) => f === 'bariatricStretcher')
    const bariatric =
      !bariatricWheelchair &&
      !bariatricStretcher &&
      level === 'Customer' &&
      customerOrVehicleData?.weight &&
      Number(customerOrVehicleData?.weight) >= 250
        ? true
        : false
    if (!hasDisability && !bariatric) return false
    const walker = features.some((f) => f?.toLowerCase() === 'walker')
    const blind = features.some((f) => f?.toLowerCase() === 'blind')
    const deaf = features.some((f) => f?.toLowerCase() === 'deaf')
    return {
      bariatric,
      wheelchair,
      bariatricWheelchair,
      stretcher,
      bariatricStretcher,
      walker,
      deaf,
      blind,
    }
  } catch (err) {
    handleErr({ err })
  }
}

const matchCustomerVehicle = ({ trip, customerData, vehicleData }) => {
  try {
    return isEmpty(trip) ||
      trip?.tripType === 'delivery' ||
      isEmpty(customerData) ||
      !customerData?.features?.length ||
      isEmpty(vehicleData) // if error
      ? true
      : (isDisability(customerData)?.bariatricWheelchair &&
          !isDisability(vehicleData)?.bariatricWheelchair) ||
        (isDisability(customerData)?.wheelchair &&
          !isDisability(vehicleData)?.wheelchair &&
          !isDisability(vehicleData)?.bariatricWheelchair) ||
        (isDisability(customerData)?.stretcher &&
          !isDisability(vehicleData)?.stretcher &&
          !isDisability(vehicleData)?.bariatricStretcher) ||
        (isDisability(customerData)?.bariatricStretcher &&
          !isDisability(vehicleData)?.bariatricStretcher)
      ? false
      : true
  } catch (err) {
    handleErr({ err })
  }
}

const matchCustomerVehicleMsg = ({ customerData, vehicleData }) => {
  try {
    const { stretcher, bariatricStretcher, wheelchair, bariatricWheelchair } =
      isDisability(customerData)
    return `Error! ${vehicleData?.name} is NOT acceptable for ${getFullName({
      user: customerData,
    })}. Please use another vehicle ${
      stretcher || wheelchair || bariatricStretcher || bariatricWheelchair
        ? `with ${
            stretcher
              ? 'Stretcher'
              : bariatricStretcher
              ? 'Bariatric Stretcher'
              : bariatricWheelchair
              ? 'Bariatric Wheelchair'
              : 'Wheelchair'
          }`
        : ''
    }.`
  } catch (err) {
    handleErr({ err })
  }
}

const getFeature = (customerOrVehicleData) => {
  try {
    const { features = [] } = customerOrVehicleData || {}
    const bariatricStretcher = features.find(
      (f) => f?.toLowerCase() === 'bariatricStretcher'?.toLowerCase(),
    )
    if (bariatricStretcher) return bariatricStretcher
    const stretcher = features.find((f) => f?.toLowerCase() === 'stretcher'?.toLowerCase())
    if (stretcher) return stretcher
    const bariatricWheelchair = features.find(
      (f) => f?.toLowerCase() === 'bariatricWheelchair'?.toLowerCase(),
    )
    if (bariatricWheelchair) return bariatricWheelchair
    const wheelchair = features.find((f) => f?.toLowerCase() === 'wheelchair'?.toLowerCase())
    if (wheelchair) return wheelchair
    const walker = features.find((f) => f?.toLowerCase() === 'walker'?.toLowerCase())
    if (walker) return walker
    const deaf = features.find((f) => f?.toLowerCase() === 'deaf'?.toLowerCase())
    if (deaf) return deaf
    const blind = features.find((f) => f?.toLowerCase() === 'blind'?.toLowerCase())
    if (blind) return blind
    return ''
    // switch (features) {
    // case features.some((f) => f?.toLowerCase() === 'bariatricStretcher'?.toLowerCase()):
    //   return 'bariatricStretcher'
    // case features.some((f) => f?.toLowerCase() === 'stretcher'):
    //   return 'stretcher'
    // case features.some((f) => f?.toLowerCase() === 'bariatricWheelchair'?.toLowerCase()):
    //   return 'bariatricWheelchair'
    // case features.some((f) => f?.toLowerCase() === 'wheelchair'):
    //   return 'wheelchair'
    // case features.some((f) => f?.toLowerCase() === 'walker'):
    //   return 'walker'
    // case features.some((f) => f?.toLowerCase() === 'deaf'):
    //   return 'deaf'
    // case features.some((f) => f?.toLowerCase() === 'blind'):
    //   return 'blind'
    // default:
    //   return ''
    // }
  } catch (err) {
    handleErr({ err })
  }
}

const featuresToFare = ({ cust, trip }) => {
  try {
    const ft = getFeature(cust)
    const { tripType } = trip || {}
    return !ft || ft === 'walker' || ft === 'deaf' || ft === 'blind'
      ? tripType === 'ride'
        ? 'standard'
        : tripType // delivery
      : ft
  } catch (err) {
    handleErr({ err })
  }
}

const radians = (n) => n * (Math.PI / 180)
const degrees = (n) => n * (180 / Math.PI)

const angle = ({ lat1, lng1, lat2, lng2 }) => {
  try {
    // function getBearing(startLat, startLong, endLat, endLong) {
    const startLat = radians(Number(lat1))
    const startLong = radians(Number(lng1))
    const endLat = radians(Number(lat2))
    const endLong = radians(Number(lng2))

    var dLong = endLong - startLong

    var dPhi = Math.log(
      Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0),
    )
    if (Math.abs(dLong) > Math.PI) {
      if (dLong > 0.0) dLong = -(2.0 * Math.PI - dLong)
      else dLong = 2.0 * Math.PI + dLong
    }

    return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0
  } catch (err) {
    handleErr({ err })
  }
}

const validCoords = ({ coords }) => {
  try {
    const coordLat = coords?.lat || coords?.latitude
    const coordLng = coords?.lng || coords?.lon || coords?.longitude
    return (
      coordLat &&
      +coordLat !== 0 &&
      +coordLat !== -1000 &&
      coordLng &&
      +coordLng !== 0 &&
      +coordLng !== -1000
    )
  } catch (err) {
    handleErr({ err })
  }
}

const modifyDeliveryTripPkTime = ({ tripsData, updateTrip, userInfo }) => {
  try {
    const countTrip = { qtt: 1 }
    return tripsData?.map((tr, i, arr) => {
      const equalizeTimes = { time: {} }
      if (tr?.tripType === 'delivery' && isPending(tr)) {
        const tripsBefore = arr
          .filter((t, aidx) => t?._id !== tr?._id && aidx < i && t?.driver === tr?.driver)
          .reverse()
        const rideAfter = arr.filter(
          (t, aidx) => aidx > i && t?.driver === tr?.driver && tr.tripType === 'ride',
        )[0]
        const latestTimeTripBefore = tripsBefore[0]
        // const latestTimeTripBefore = tripsBefore?.sort(sortTripsByLatestTime)?.reverse()[0]
        // const latestTimeTripAfter = rideAfter?.sort(sortTripsByLatestTime)?.reverse()[0]
        // const latestPkTrip = tripsBefore?.sort(sortByPkTime)?.reverse()[0]
        if (
          !isEmpty(latestTimeTripBefore) &&
          !isEmpty(latestTimeTripBefore.times) &&
          latestTimeTripBefore?.driver === tr?.driver
        ) {
          const { timeIdx, times } = tripInfo({ trip: tr })
          // const { timeIdx: latestTimeIdx } = tripInfo({ trip: latestTimeTripBefore })
          const tTime = latestStartStopTime({ trip: tr })
          // tr?.times[`expectedStartTime${timeIdx}`] || tr?.times[`expectedPickupTime${timeIdx}`]
          const latestPkAvDpTimeBefore = latestStartStopTime({ trip: latestTimeTripBefore })
          const latestPkAvDpTimeAfter = rideAfter ? latestStartStopTime({ trip: rideAfter }) : ''
          // latestPkTrip?.times[`expectedStartTime${latestTimeIdx}`] ||
          // latestPkTrip?.times[`expectedPickupTime${latestTimeIdx}`]
          if (
            tTime < latestPkAvDpTimeBefore
            // ||
            // (latestTimeTripBefore?.tripType === 'ride' &&
            //   (!latestPkAvDpTimeAfter || (latestPkAvDpTimeAfter && tTime >= latestPkAvDpTimeAfter)))
          ) {
            equalizeTimes.time = {
              [`expectedStartTime${timeIdx}`]: times[`expectedStartTime${timeIdx}`]
                ? latestPkAvDpTimeBefore
                : null,
              [`expectedPickupTime${timeIdx}`]: latestPkAvDpTimeBefore,
              [`expectedDropoffTime${timeIdx}`]: latestPkAvDpTimeBefore,
            }
            arr[i] = {
              ...arr[i],
              times: {
                ...arr[i].times,
                ...equalizeTimes.time,
              },
            }
          }
          if (
            userIsTripDriver({ trip: tr, id: userInfo?._id }) &&
            updateTrip &&
            !areEquals(tr.times, arr[i].times)
          ) {
            setTimeout(() => {
              updateTrip({
                trips: [tr],
                formData: {
                  time: equalizeTimes.time,
                },
              })
            }, countTrip * 333)
            countTrip.qtt++
          }
        }
      }
      return {
        ...tr,
        times: {
          ...tr.times,
          ...equalizeTimes.time,
        },
        // autoRouted: false,
      }
      // }
    })
  } catch (err) {
    handleErr({ err })
  }
}

const cleanTrip = (trip) => {
  if (isEmpty(trip)) return trip
  const tCopy = JSON.parse(JSON.stringify(trip))
  delete tCopy.editors
  delete tCopy.notes
  delete tCopy.destData
  delete tCopy.dest1
  delete tCopy.dest2
  delete tCopy.tripCustomer
  delete tCopy.inspectionState
  delete tCopy.removeDriver
  delete tCopy.createRoute
  delete tCopy.destIdxToShow
  delete tCopy.destPending
  delete tCopy.destLen
  delete tCopy.idxToGo
  delete tCopy.isStart
  delete tCopy.isArrive
  delete tCopy.isPickup
  delete tCopy.isDropoff
  delete tCopy.lastProgress
  delete tCopy.lastProgress
  delete tCopy.timeIdx
  delete tCopy.stepVal
  delete tCopy.expectedPickupTime1
  delete tCopy.expectedDropoffTime1
  return tCopy
}

const cleanTrips = (trips) => {
  if (trips?.length >= 0) {
    return trips.map((t) => cleanTrip(t))
  }
  if (!isEmpty(trips)) return cleanTrip(trips)
}

const getPlanName = ({ agreement }) => agreement?.description?.split(' ')[1]

const getVehiclesPaid = ({ agreementAmount, discount, planPrice }) => {
  if (!discount) return parseFloat((+agreementAmount / +planPrice)?.toFixed(3))
  let i = 1
  const calcAmount = (i) =>
    parseFloat((i * +planPrice - (+discount / 100) * i * +planPrice)?.toFixed(3))
  while (agreementAmount >= calcAmount(i)) {
    // console.log(agreementAmount, calcAmount(i), i)
    if (+agreementAmount <= calcAmount(i)) return i
    i++
  }
}

const someFacility = ({ facility, user }) => {
  try {
    return (
      (!facility?.length && !user?.facility?.length) ||
      (facility?.length && facility?.some((f) => user?.facility?.some((uf) => uf === f))) ||
      isSuperadmin(user)
    )
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const insuranceData = ({ user, trip }) => {
  try {
    const { insurance: userInsurance, insuranceId: userInsuranceId } = user || {}
    const { insurance: tripInsurance, insuranceId: tripInsuranceId } = trip || {}
    return !isEmpty(userInsurance)
      ? {
          ...userInsurance,
          insuranceId: userInsuranceId,
        }
      : !isEmpty(tripInsurance)
      ? {
          ...tripInsurance,
          insuranceId: tripInsuranceId,
        }
      : null
  } catch (err) {
    handleErr({ err })
    return null
  }
}

const brokerData = ({ user, trip }) => {
  try {
    const { broker: userBroker, brokerId: userBrokerId } = user || {}
    const { broker: tripBroker, brokerId: tripBrokerId } = trip || {}
    return !isEmpty(userBroker)
      ? {
          ...userBroker,
          brokerId: userBrokerId,
        }
      : !isEmpty(tripBroker)
      ? {
          ...tripBroker,
          brokerId: tripBrokerId,
        }
      : null
  } catch (err) {
    handleErr({ err })
    return null
  }
}

const getFare = ({ user, trip, idx, brokerPricingData }) => {
  try {
    const { tripIdx, times } = trip || {}
    const tIdx = idx || tripIdx
    const isTripCanceled = trip?.tripState === 'canceled'
    const tripDist = idx
      ? getFullTripDist({ trip })?.length
        ? getFullTripDist({ trip })[tIdx ? tIdx - 1 : 0]
        : 0
      : getFullTripDist({ trip })?.length
      ? getFullTripDist({ trip })?.reduce((acc, val) => acc + val)
      : 0
    const isArriveTime = times[`arriveTime${tIdx || 1}`] || tripDist // if canceled going to customer
    const halfPrice = (price = 0) => (isTripCanceled && isArriveTime ? price / 2 : price)
    return user?.fixedFare && user?.fare
      ? user?.fare
      : isTripCanceled && !isArriveTime && !tripDist
      ? 0
      : trip?.tripType === 'delivery'
      ? Number(halfPrice(brokerPricingData?.delivery) || 0) //|| user?.fare || brokerPricingData?.standard
      : user?.fare
      ? Number(halfPrice(user?.fare))
      : user?.features?.find((f) => f === 'bariatricStretcher') &&
        brokerPricingData?.bariatricStretcher
      ? Number(halfPrice(brokerPricingData?.bariatricStretcher))
      : user?.features?.find((f) => f?.toLowerCase() === 'stretcher') &&
        brokerPricingData?.stretcher
      ? Number(halfPrice(brokerPricingData?.stretcher))
      : user?.features?.find((f) => f === 'bariatricWheelchair') &&
        brokerPricingData?.bariatricWheelchair
      ? Number(halfPrice(brokerPricingData?.bariatricWheelchair))
      : user?.features?.find((f) => f?.toLowerCase() === 'wheelchair') &&
        brokerPricingData?.wheelchair
      ? Number(halfPrice(brokerPricingData?.wheelchair))
      : Number(halfPrice(brokerPricingData?.standard) || 0)
  } catch (err) {
    handleErr({ err })
  }
}

const extraMilePerFare = ({ fare, brokerPricingData }) => {
  try {
    switch (fare) {
      case 'delivery':
        return Number(brokerPricingData?.deliveryExtramile)
      case 'standard':
        return Number(brokerPricingData?.standardExtramile)
      case 'wheelchair':
        return Number(brokerPricingData?.wheelchairExtramile)
      case 'bariatricWheelchair':
        return Number(brokerPricingData?.bariatricWheelchairExtramile)
      case 'stretcher':
        return Number(brokerPricingData?.stretcherExtramile)
      case 'bariatricStretcher':
        return Number(brokerPricingData?.bariatricStretcherExtramile)
      default:
        return brokerPricingData?.standard
    }
  } catch (err) {
    handleErr({ err })
  }
}

export {
  getFare,
  extraMilePerFare,
  insuranceData,
  brokerData,
  getActiveDrivers,
  getActiveCustomers,
  getActiveVehicles,
  isFound,
  getIsDriving,
  getTripsPendingByDriver,
  getFullTripDist,
  getPkDpDist,
  currentDriver,
  sortByPkTime,
  latestStartStopTime,
  sortTripsByLatestTime,
  sortByOneDest,
  sortByPriority,
  sortByDistance,
  sortByRouteIndex,
  sortByOnTrip,
  sortByNext,
  sortByArrive,
  sortByName,
  sortByFirstName,
  sortRouteAI,
  isGroup,
  isRide,
  isDelivery,
  isPending,
  isEnded,
  isCanceled,
  isNotGroupPickedUp,
  getTripCust,
  getFullName,
  getNameInitials,
  getFullAddress,
  getPhone,
  getTripDestination,
  linearDistance,
  isNearBy,
  getRemainingItems,
  ObjOrdered,
  arrUniq,
  uniqueById,
  uniqueByKey,
  areEquals,
  shortName,
  docEl,
  calcOilLife,
  userIsTripDriver,
  isSameCustomerAndPk,
  uniqueByCustomerAndPickup,
  validateEmail,
  groupBy,
  debounce,
  onlyDestinations,
  getCompanyName,
  validVin,
  uInfo,
  usersByLevel,
  isDisability,
  matchCustomerVehicle,
  matchCustomerVehicleMsg,
  getFeature,
  featuresToFare,
  radians,
  degrees,
  angle,
  validCoords,
  modifyDeliveryTripPkTime,
  cleanTrips,
  getPlanName,
  getVehiclesPaid,
  sortByCustomerReady,
  sortByDriver,
  sortByEnded,
  sortByCanceled,
  someFacility,
  isScheduled,
}
