import { toast } from 'react-toastify'
import socket from 'src/services/socket'
import { axiosPrivate, axiosPublic } from '../services/api'
import { BASE_URL } from '../services/api'
import {
  CREATE_USER_START,
  CREATE_USER_FAIL,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_FAIL,
  DELETE_USER_START,
  DELETE_USER_FAIL,
  GET_DESTINATIONS_START,
  GET_DESTINATIONS_SUCCESS,
  GET_DESTINATIONS_FAIL,
  CREATE_DESTINATIONS_START,
  CREATE_DESTINATIONS_FAIL,
  DELETE_DESTINATION_START,
  DELETE_DESTINATION_FAIL,
  UPDATE_DESTINATION_START,
  UPDATE_DESTINATION_FAIL,
  CREATE_TRIP_START,
  CREATE_TRIP_FAIL,
  GET_TRIPS_SUCCESS,
  GET_TRIPSREPORT_SUCCESS,
  GET_TRIPSREPORT_FAIL,
  GET_TRIPS_FAIL,
  GET_TRIP_START,
  GET_TRIP_SUCCESS,
  GET_TRIP_FAIL,
  UPDATE_TRIP_FAIL,
  DELETE_TRIP_START,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_FAIL,
  DEACTIVATE_TRIP_START,
  DEACTIVATE_TRIP_SUCCESS,
  DEACTIVATE_TRIP_FAIL,
  GET_ALL_COMPANIES_START,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAIL,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DEACTIVATE_COMPANY_START,
  DEACTIVATE_COMPANY_SUCCESS,
  DEACTIVATE_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  DELETE_COMPANY_START,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  CREATE_COUPON_START,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  GET_COUPONS_START,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  DELETE_COUPON_START,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  UPDATE_COUPON_START,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  GET_COUPON_START,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  GET_TRANSACTIONS_START,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  DELETE_TRANSACTION_START,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,
  GET_BILLING_AGREEMENT_START,
  GET_BILLING_AGREEMENT_SUCCESS,
  GET_BILLING_AGREEMENT_FAIL,
  CANCEL_BILLING_AGREEMENT_FAIL,
  CANCEL_BILLING_AGREEMENT_SUCCESS,
  CANCEL_BILLING_AGREEMENT_START,
  UPDATE_BILLING_AGREEMENT_START,
  UPDATE_BILLING_AGREEMENT_SUCCESS,
  UPDATE_BILLING_AGREEMENT_FAIL,
  // UPDATE_AGREEMENT_PRICING_START,
  // UPDATE_AGREEMENT_PRICING_SUCCESS,
  // UPDATE_AGREEMENT_PRICING_FAIL,
  CREATE_VEHICLE_START,
  // CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_START,
  UPDATE_VEHICLE_FAIL,
  DELETE_VEHICLE_START,
  // DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  // GET_VEHICLE_START,
  // GET_VEHICLE_SUCCESS,
  // GET_VEHICLE_FAIL,
  // GET_VEHICLES_START,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  // DEACTIVATE_VEHICLE_SUCCESS,
  // DEACTIVATE_VEHICLE_FAIL,
  // DEACTIVATE_VEHICLE_START,
  // CREATE_USER_SUCCESS,
  // UPDATE_USER_SUCCESS,
  // CREATE_DESTINATIONS_SUCCESS,
  // UPDATE_DESTINATION_SUCCESS,
  // DEACTIVATE_DESTINATION_SUCCESS,
  // DELETE_DESTINATION_SUCCESS,
  // CREATE_VEHICLE_SUCCESS,
  // UPDATE_TRIP_RESET,
  UPDATE_QUICKNOTES,
  GET_COMPANY_START,
  GET_COMPANY_SETTINGS_START,
  GET_COMPANY_SETTINGS_SUCCESS,
  GET_COMPANY_SETTINGS_FAIL,
  UPDATE_TRIP_START,
  UPDATE_INSPECTION_START,
  UPDATE_INSPECTION_FAIL,
  CREATE_INSPECTION_START,
  CREATE_INSPECTION_FAIL,
  GET_INSPECTION_SUCCESS,
  GET_INSPECTIONS_SUCCESS,
  // UPDATE_INSPECTION_SUCCESS,
  GET_INSPECTIONS_START,
  GET_INSPECTIONS_FAIL,
  GET_INSPECTION_FAIL,
  DELETE_SCHEDULED_TRIP_START,
  DELETE_SCHEDULED_TRIP_SUCCESS,
  DELETE_SCHEDULED_TRIP_FAIL,
  // UPDATE_USER_SUCCESS,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  CREATE_PRODUCTS_START,
  CREATE_PRODUCTS_FAIL,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_START,
  DELETE_PRODUCT_FAIL,
  GET_CUSTOMER_PRODUCTS_START,
  GET_CUSTOMER_PRODUCTS_SUCCESS,
  GET_CUSTOMER_PRODUCTS_FAIL,
  GET_PRODUCT_LOGS_START,
  GET_PRODUCT_LOGS_SUCCESS,
  GET_PRODUCT_LOGS_FAIL,
  GET_VEHICLE_PRODUCTS_START,
  GET_VEHICLE_PRODUCTS_SUCCESS,
  GET_VEHICLE_PRODUCTS_FAIL,
  GET_TRIPS_START,
  GET_BROKERS_START,
  GET_BROKERS_SUCCESS,
  GET_BROKERS_FAIL,
  CREATE_BROKER_START,
  CREATE_BROKER_FAIL,
  UPDATE_BROKER_START,
  UPDATE_BROKER_FAIL,
  DELETE_BROKER_START,
  DELETE_BROKER_FAIL,
} from '../constants'
import isEmpty from 'src/utils/is-empty'
import { companyLogout } from './companyActions'
import { isInTimeRange, isPast, todayRange } from 'src/utils/handle-date'
import handleErr from 'src/utils/handle-error'
import handleIDB from 'src/utils/handle-indexed-db'

///////////////////////////////////////////// USERS /////////////////////////////////////////////

// export const getCompanyUser = (payload) => async (dispatch) => {
//   dispatch({ type: GET_USER_START })
//   const model = 'User'
//   const { id, level = 'undefined' } = payload
//   try {
//     const url = `${BASE_URL}/user/${id}/${level}`

//     // const res = await fetchPrivate({ url })
//     // const data = await res.json()
//     const axPriv = await axiosPrivate()
//     const { data } = await axPriv.get(url)
//     if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

//     dispatch({ type: GET_USER_SUCCESS, payload: data.user })
//   } catch (err) {
//     console.log(err)
//     const data = await handleIDB.getVal({ model, _id: id })
//     const type = data ? GET_USER_SUCCESS : GET_USER_FAIL
//     const payload = data || err.message || err.response?.data?.errors
//     dispatch({ type, payload })
//     // dispatch({ type: GET_USER_FAIL, payload: err.response?.data?.errors })
//   }
// }
export const getUsersAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_USERS_START })
  const model = 'User'
  const { cb, userById, level, exclude, includeDeactivated } = payload
  try {
    // const { data } = await axiosPrivate.get(
    //   `/companies/users?includeDeactivated=${!!payload?.includeDeactivated}${level}`,
    // )
    // payload.includeDeactivated = true
    const url = `${BASE_URL}/users?includeDeactivated=${!!includeDeactivated}${
      level ? `&level=${level}` : ''
    }${userById ? `&userById=${userById}` : ''}${exclude ? `&exclude=${exclude}` : ''}`.trim()

    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(url)
    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const { success, users = [], companySettings, errors } = data || {}
    if (!success) throw new Error(errors)

    if (companySettings?.isDeactivated) {
      return dispatch(companyLogout())
    }

    dispatch({ type: GET_USERS_SUCCESS, payload: users })
    cb && cb({ users })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        const type = data ? GET_USERS_SUCCESS : GET_USERS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb({ users: data || [] })
      },
    })
  }
}

export const createUser = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_USER_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.post('/users/register', payload)

    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createUser`, payload)
    socket.once(`userCreated${userInfo?._id}`, (dbPayload) => {
      const { success, errors, userCreatedId } = dbPayload
      if (success) {
        // dispatch({
        //   type: CREATE_USER_SUCCESS,
        //   payload: { user: dbPayload.user },
        // })
        toast.success(`User Created`, { toastId: userInfo._id })
        cb && cb(userCreatedId)
      } else {
        toast.error(`Error Creating User`, { toastId: userInfo._id })
        dispatch({ type: CREATE_USER_FAIL, payload: errors })
      }
    })
    // const { data } = await axPriv.post('/users/register', formData)

    // dispatch({ type: CREATE_USER_SUCCESS, payload: { msg: data.msg, user: data.user } })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating User`,
      },
      reduxType: CREATE_USER_FAIL,
    })
    // dispatch({ type: CREATE_USER_FAIL, payload: err.response?.data?.errors })
  }
}
export const updateUser = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.patch('/users/' + payload.id, payload.formState)
    const { userInfo, cb } = payload
    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateUser`, payload)
    socket.once(`userUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_USER_SUCCESS,
        //   payload: { user: dbPayload.user },
        // })
        toast.success(`User Updated`, { toastId: userInfo._id })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        toast.error(`Error Updating User`, { toastId: userInfo._id })
        dispatch({ type: UPDATE_USER_FAIL, payload: errors })
      }
    })

    // const { data } = await axPriv.patch('/users/' + payload.id, payload.formState)
    // dispatch({ type: UPDATE_USER_SUCCESS, payload: { user: data.user, msg: data.msg } })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating User`,
      },
      reduxType: UPDATE_USER_FAIL,
    })
    // dispatch({ type: UPDATE_USER_FAIL, payload: err.response?.data?.errors })
  }
}
export const updateUserLastLocation = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.patch('/users/' + payload.id, payload.formState)
    const { _id: id } = payload
    // if (!socket.connected && id) socket.connect()
    socket.emit(`updateUserLastLocation`, payload)
    socket.once(`userLastLocationUpdated${id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_USER_SUCCESS,
        //   payload: { user: dbPayload.user },
        // })
        // cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
        // console.log(success)
      } else {
        dispatch({ type: UPDATE_USER_FAIL, payload: errors })
      }
    })
    // const { data } = await axPriv.patch('/users/' + payload.id, payload.formState)
    // dispatch({ type: UPDATE_USER_SUCCESS, payload: { user: data.user, msg: data.msg } })
  } catch (err) {
    handleErr({ err, dispatch, reduxType: UPDATE_USER_FAIL })
    // dispatch({ type: UPDATE_USER_FAIL, payload: err.response?.data?.errors })
  }
}
export const toggleDriverBreak = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.patch('/users/' + payload.id, payload.formState)
    const { userInfo, cb } = payload
    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`toggleDriverBreak`, payload)
    socket.once(`driverBreakToggled${userInfo?._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_USER_SUCCESS,
        //   payload: { user: dbPayload.user },
        // })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
        // console.log(success)
      } else {
        dispatch({ type: UPDATE_USER_FAIL, payload: errors })
      }
    })
    // const { data } = await axPriv.patch('/users/' + payload.id, payload.formState)
    // dispatch({ type: UPDATE_USER_SUCCESS, payload: { user: data.user, msg: data.msg } })
  } catch (err) {
    handleErr({ err, dispatch, reduxType: UPDATE_USER_FAIL })
    // dispatch({ type: UPDATE_USER_FAIL, payload: err.response?.data?.errors })
  }
}
export const deactivateUserAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_START })
  // dispatch({ type: DEACTIVATE_USER_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.put('/companies/users/activation/' + payload.id)
    // const { data } = await axPriv.put('/companies/users/activation/' + payload.id)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`toggleUser`, payload)
    socket.once(`userToggled${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_USER_SUCCESS,
        //   payload: { user: dbPayload.user },
        // })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        dispatch({ type: UPDATE_USER_FAIL, payload: errors })
      }
    })

    // dispatch({ type: DEACTIVATE_USER_SUCCESS, payload: { user: data.user, msg: data.msg } })
    // if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error activating/disabling User`,
      },
      reduxType: UPDATE_USER_FAIL,
    })
    // dispatch({ type: UPDATE_USER_FAIL, payload: err.response?.data?.errors })
  }
}
export const deleteUserAction = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_USER_START })
  const { id, cb } = payload
  try {
    const axPriv = await axiosPrivate()
    // await axPriv.delete('/users/delete-user/' + payload.id)
    const { data } = await axPriv.delete('/users/delete-user/' + id)
    const { success } = data
    // dispatch({ type: DELETE_USER_SUCCESS, payload: data })
    if (success && cb) cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: true,
      reduxType: DELETE_USER_FAIL,
    })
    // dispatch({ type: DELETE_USER_FAIL, payload: err.response?.data?.errors })
  }
}

// //////////////////////////////////////////// TRIPS /////////////////////////////////////////////

export const getTripsAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_TRIPS_START })
  const model = 'Trip'
  const {
    creator,
    tripState,
    constraint,
    // clientTimeNow, // = Date.now(), //new Date().toLocaleString(),
    level,
    customer,
    destination,
    driver,
    scheduledTripsOnly,
    getDriverLocations,
    cb,
    page,
    selectedCompany,
  } = payload
  try {
    const url = `${BASE_URL}/trips/companies?${
      constraint ? `constraint=${constraint}` : ''
    }${`&page=${page || 1}`}${creator ? `&creator=${creator}` : ''}${
      selectedCompany ? `&selectedCompany=${selectedCompany}` : ''
    }${driver ? `&driver=${driver}` : ''}${level ? `&level=${level}` : ''}${
      tripState ? `&tripState=${tripState}` : ''
    }${customer ? `&customer=${customer}` : ''}${destination ? `&destination=${destination}` : ''}${
      getDriverLocations ? `&getDriverLocations=${getDriverLocations}` : ''
    }${scheduledTripsOnly ? `&scheduledTripsOnly=${scheduledTripsOnly}` : ''}`.trim()
    // const url = `${BASE_URL}/trips/companies?${constraint ? `constraint=${constraint}` : ''}${
    //   driver ? `&driver=${driver}` : ''
    // }${level ? `&level=${level}` : ''}${tripState ? `&tripState=${tripState}` : ''}${
    //   customer ? `&customer=${customer}` : ''
    // }${destination ? `&destination=${destination}` : ''}${
    //   getDriverLocations ? `&getDriverLocations=${getDriverLocations}` : ''
    // }${clientTimeNow ? `&clientTimeNow=${clientTimeNow}` : ''}`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()

    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(url)
    const { success, trips = [], scheduledTrips = [] } = data || {}
    // const { userInfo } = useSelector((state) => state?.companyAuth)
    // if (!socket.connected) socket.connect()
    // socket.emit('getTrips', payload)
    // console.log(payload)
    // socket.once(payload.userInfo._id, (trips) => {
    //   // socket.once('dbTrips', (trips) => {
    //   console.log(trips)
    //   dispatch({ type: GET_TRIPS_SUCCESS, payload: trips })
    // })

    // if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

    // const { data } = await axiosPrivate.get(url)

    // for (const trip of data.trips) {
    //   pushDataOffline({
    //     model,
    //     _id: trip._id,
    //     action: 'put',
    //     data: trip,
    //   })
    // }

    // dispatch({ type: GET_TRIPS_SUCCESS, payload: data.trips })
    // if (data?.companySettings?.isDeactivated) {
    //   return dispatch(companyLogout())
    // }
    dispatch({
      type: GET_TRIPS_SUCCESS,
      payload: trips,
      scheduledTrips,
    })
    cb && cb({ success, trips })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        const type = data ? GET_TRIPS_SUCCESS : GET_TRIPS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb({ trips: data || [] })
      },
    })
  }
}

export const getTripsReport = (payload) => async (dispatch) => {
  // dispatch({ type: GET_TRIPS_START })
  const model = 'Tripsreport'
  const {
    reportOnly,
    tripState,
    constraint,
    // clientTimeNow, // = Date.now(), //new Date().toLocaleString(),
    level,
    customer,
    destination,
    driver,
    cb,
  } = payload
  try {
    const url = `${BASE_URL}/trips/companies?${constraint ? `constraint=${constraint}` : ''}${
      driver ? `&driver=${driver}` : ''
    }${level ? `&level=${level}` : ''}${tripState ? `&tripState=${tripState}` : ''}${
      customer ? `&customer=${customer}` : ''
    }${destination ? `&destination=${destination}` : ''}${
      reportOnly ? `&reportOnly=${reportOnly}` : ''
    }`.trim()
    const dataIDB = await handleIDB.getVal({ model, _id: constraint })

    if (dataIDB && !dataIDB.pending && isPast({ constraint1: constraint?.split('_')[0] })) {
      dispatch({
        type: GET_TRIPSREPORT_SUCCESS,
        payload: dataIDB,
      })
    } else {
      // const res = await fetchPrivate({ url })
      // const data = await res.json()

      const axPriv = await axiosPrivate()
      const { data } = await axPriv.get(url)
      dispatch({ type: GET_TRIPSREPORT_SUCCESS, payload: data.tripsReport })
      await handleIDB.put({ model, _id: data.tripsReport.constraint, data: data.tripsReport })
    }

    cb && cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        const type = data ? GET_TRIPSREPORT_SUCCESS : GET_TRIPSREPORT_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb({ trips: data || [] })
      },
    })
  }
}

export const getTripAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_TRIP_START })
  const { id } = payload
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get('/trips/' + id)

    dispatch({ type: GET_TRIP_SUCCESS, payload: data.trip })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getVal({ model: 'Trip', _id: id })
        const type = data ? GET_TRIP_SUCCESS : GET_TRIP_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
      },
    })
  }
}
//to app.js with socket
export const createTripAction = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_TRIP_START })

  try {
    // const axPriv = await axiosPrivate()
    // const { data } = await axPriv.post('/trips', payload.formData)
    const { userInfo, formData, cb } = payload
    if (formData.addnote) {
      dispatch({ type: UPDATE_QUICKNOTES, payload: { newQn: { content: formData.addnote } } })
    }

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createTrip`, payload)
    socket.once(`tripCreated${userInfo._id}`, (dbPayload) => {
      const { trip, success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: CREATE_TRIP_SUCCESS,
        //   payload: { trip: dbPayload.trip, msg: dbPayload.msg },
        // })
        // if (cb) cb(dbPayload?.trip?._id)
        toast.success(`Trip Created`, { toastId: userInfo._id })
        cb && cb({ trip, success })
      } else {
        cb && cb({ success })
        // toast.error(`Error Creating Trip`, { toastId: userInfo._id })
        // dispatch({ type: CREATE_TRIP_FAIL, payload: errors })
        handleErr({
          alert: true,
          err: errors,
          dispatch,
          reduxType: CREATE_TRIP_FAIL,
          payload: errors,
        })
      }
    })

    // console.log(data)
    // dispatch({ type: CREATE_TRIP_SUCCESS, payload: data })
    // console.log(data)
    // if (payload.cb) payload.cb(data?.trip?._id)
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating Trip`,
      },
      reduxType: CREATE_TRIP_FAIL,
    })
    // dispatch({ type: CREATE_TRIP_FAIL, payload: err.response?.data?.errors })
  }
}
//to app.js with socket
export const updateTripAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_TRIP_START })

  try {
    // const { formData, id, cb } = payload
    // console.log(payload)
    // const axPriv = await axiosPrivate()
    // const { data } = await axPriv.patch('/trips/' + id, formData)
    const { id, userInfo, formData, cb } = payload
    if (formData.addnote) {
      dispatch({ type: UPDATE_QUICKNOTES, payload: { newQn: { content: formData.addnote } } })
    }
    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateTrip`, { id, userInfo, formData })
    socket.once(`tripUpdated${userInfo._id}`, (dbPayload) => {
      setTimeout(() => {
        // dispatch({ type: UPDATE_TRIP_SUCCESS, payload: dbPayload })
        const { success, errors } = dbPayload
        // console.log(dbPayload)
        cb && cb({ success })
        if (!success) {
          // toast.error(errors[0], { toastId: userInfo._id })
          // cb && cb({ success })
          // dispatch({ type: UPDATE_TRIP_FAIL, payload: errors })
          handleErr({
            alert: true,
            err: errors,
            dispatch,
            reduxType: UPDATE_TRIP_FAIL,
            payload: errors,
          })
        }
      }, 77)
    })

    // console.log(payload)

    // if (data.success) {
    //   dispatch({
    //     type: UPDATE_TRIP_SUCCESS,
    //     payload: { trip: data.trip, msg: data.msg },
    //   })
    //   if (cb) cb(data?.trip?._id)
    // }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: UPDATE_TRIP_FAIL,
    })
    // dispatch({ type: UPDATE_TRIP_FAIL, payload: err.response?.data?.errors })
  }
}

export const deactivateTripAction = (payload) => async (dispatch) => {
  dispatch({ type: DEACTIVATE_TRIP_START })

  try {
    const { data } = await axiosPrivate.put('/trips/activation/' + payload.id)

    dispatch({ type: DEACTIVATE_TRIP_SUCCESS, payload: data })
    if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: DEACTIVATE_TRIP_FAIL,
    })
    // dispatch({ type: DEACTIVATE_TRIP_FAIL, payload: err.response?.data?.errors })
  }
}

export const deleteTrip = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_TRIP_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.delete('/trips/' + payload.id)

    dispatch({ type: DELETE_TRIP_SUCCESS, payload: data })
    payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: DELETE_TRIP_FAIL,
    })
    // dispatch({ type: DELETE_TRIP_FAIL, payload: err.response?.data?.errors })
  }
}

export const deleteScheduledTrip = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_SCHEDULED_TRIP_START })

  try {
    const { id, cb } = payload
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.delete('/trips/scheduled/' + id)
    const { trip, success, msg } = data
    dispatch({ type: DELETE_SCHEDULED_TRIP_SUCCESS, payload: trip })
    cb && cb()
    toast[success ? 'success' : 'error'](success ? msg : 'Error Deleting Scheduled Trip')
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Deleting Trip`,
      },
      reduxType: DELETE_SCHEDULED_TRIP_FAIL,
    })
    // dispatch({ type: DELETE_SCHEDULED_TRIP_FAIL, payload: err.response?.data?.errors })
  }
}

///////////////////////////////////////////// DESTNATION /////////////////////////////////////////////

export const getDestinationsAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_DESTINATIONS_START })
  const model = 'Destination'
  const { cb, isFacility, includeDeactivated, name, zipcode, destById, selectedCompany } = payload
  try {
    const url = `${BASE_URL}/destinations/companies?${
      selectedCompany ? `selectedCompany=${selectedCompany}` : ''
    }${isFacility ? `&isFacility=${isFacility}` : ''}${
      includeDeactivated ? `&includeDeactivated=true` : ''
    }${name ? `&name=${name}` : ''}${zipcode ? `&zipcode${zipcode}` : ''}${
      destById ? `&destById=${destById}` : ''
    }`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const axPriv = await axiosPrivate()
    const { data = {} } = await axPriv.get(url)
    const { success, destinations } = data
    if (success) {
      dispatch({ type: GET_DESTINATIONS_SUCCESS, payload: destinations })
      cb && cb({ dests: destinations })
    } else {
      const err = 'Something went wrong, please try again later'
      handleErr({
        err,
        dispatch,
        customFunc: async () => {
          const data = await handleIDB.getAll({ model })
          console.log(err, data)
          const type = data ? GET_DESTINATIONS_SUCCESS : GET_DESTINATIONS_FAIL
          const payload = data || err.message || err.response?.data?.errors
          dispatch({ type, payload })
          cb && cb({ dest: data || [] })
        },
      })
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        console.log(err, data)
        const type = data ? GET_DESTINATIONS_SUCCESS : GET_DESTINATIONS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb({ dest: data || [] })
      },
    })
  }
}

// export const getCompanyDestination = (payload) => async (dispatch) => {
//   dispatch({ type: GET_DESTINATION_START })
//   const model = 'Destination'
//   const _id = payload.id
//   try {
//     const url = `${BASE_URL}/destinations/${_id}`
//     // const { data } = await axiosPrivate.get('/destinations/' + payload.id)
//     const axPriv = await axiosPrivate()
//     const { data } = await axPriv.get(url)

//     // const res = await fetchPrivate({ url })
//     // const data = await res.json()
//     if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

//     // pushDataOffline({
//     //   model,
//     //   action: 'put',
//     //   data: data.destination,
//     // })

//     dispatch({ type: GET_DESTINATION_SUCCESS, payload: data.destination })
//   } catch (err) {
//     handleErr({
//       err,
//       dispatch,
//       customFunc: async () => {
//         const data = await handleIDB.getVal({ model, _id })
//         const type = data ? GET_DESTINATION_SUCCESS : GET_DESTINATION_FAIL
//         const payload = data || err.message || err.response?.data?.errors
//         dispatch({ type, payload })
//       },
//     })
//     // dispatch({ type: GET_DESTINATION_FAIL, payload: err.response?.data?.errors })
//   }
// }

// export const getHospitalsDestinations = (payload) => async (dispatch) => {
//   dispatch({ type: GET_HOSPITALS_DESTINATIONS_START })

//   try {
//     const axPriv = await axiosPrivate()
//     const { data } = await axPriv.get(`/destinations/hospitals?state=${payload || ''}`)

//     dispatch({ type: GET_HOSPITALS_DESTINATIONS_SUCCESS, payload: data })
//   } catch (err) {
//     handleErr({
//       err,
//       dispatch,
//       reduxType: GET_HOSPITALS_DESTINATIONS_FAIL,
//     })
//     // dispatch({ type: GET_HOSPITALS_DESTINATIONS_FAIL, payload: err.response?.data?.errors })
//   }
// }
//to app.js with socket
export const createDestinationAction = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_DESTINATIONS_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.post('/destinations', formData)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createDestination`, payload)
    socket.once(`createdDestination${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: CREATE_DESTINATIONS_SUCCESS,
        //   payload: { destination: dbPayload.destination },
        // })
        toast.success(`Created Succesfully`, { toastId: userInfo._id })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        toast.error(`Error Creating`, { toastId: userInfo._id })
        dispatch({ type: CREATE_DESTINATIONS_FAIL, payload: errors })
      }
    })
    // const { data } = await axPriv.post('/destinations', formData)
    // dispatch({ type: CREATE_DESTINATIONS_SUCCESS, payload: data })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating`,
      },
      reduxType: CREATE_DESTINATIONS_FAIL,
    })
    // dispatch({ type: CREATE_DESTINATIONS_FAIL, payload: err.response?.data?.errors })
  }
}
//to app.js with socket
export const updateDestinationAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_DESTINATION_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.put('/destinations/' + payload.id, payload.formState)
    // const { data } = await axPriv.put('/destinations/' + payload.id, payload.formState)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateDestination`, payload)
    socket.once(`destinationUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_DESTINATION_SUCCESS,
        //   payload: { destination: dbPayload.destination },
        // })
        toast.success(`Updated Succesfully`, { toastId: userInfo._id })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        toast.success(`Error Updating`, { toastId: userInfo._id })
        dispatch({ type: UPDATE_DESTINATION_FAIL, payload: errors })
      }
    })
    // dispatch({
    //   type: UPDATE_DESTINATION_SUCCESS,
    //   payload: { destination: data.destination, msg: data.msg },
    // })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating`,
      },
      reduxType: UPDATE_DESTINATION_FAIL,
    })
    // dispatch({ type: UPDATE_DESTINATION_FAIL, payload: err.response?.data?.errors })
  }
}
//to app.js with socket
export const deactivateDestinationAction = (payload) => async (dispatch) => {
  // dispatch({ type: DEACTIVATE_DESTINATION_START })
  dispatch({ type: UPDATE_DESTINATION_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.put('/destinations/activation/' + payload.id)
    // const { data } = await axPriv.put('/destinations/activation/' + payload.id)
    const { userInfo } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`toggleDestination`, payload)
    socket.once(`destinationToggled${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_DESTINATION_SUCCESS,
        //   payload: { destination: dbPayload.destination },
        // })
        if (payload.cb) payload.cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        dispatch({ type: UPDATE_DESTINATION_FAIL, payload: errors })
      }
    })
    // dispatch({ type: DEACTIVATE_DESTINATION_SUCCESS, payload: data })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error !!! Please try again later`,
      },
      reduxType: UPDATE_DESTINATION_FAIL,
    })
    // dispatch({ type: UPDATE_DESTINATION_FAIL, payload: err.response?.data?.errors })
  }
}
//to app.js with socket
export const deleteDestinationAction = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_DESTINATION_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.delete('/destinations/' + payload.id)
    // const { data } = await axPriv.delete('/destinations/' + payload.id)
    const { userInfo } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`deleteDestination`, payload)
    socket.once(`destinationDeleted${userInfo._id}`, (dbPayload) => {
      const { success } = dbPayload
      if (success) {
        // dispatch({
        //   type: DELETE_DESTINATION_SUCCESS,
        //   payload: { destination },
        // })
        if (payload.cb) payload.cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        dispatch({ type: DELETE_DESTINATION_FAIL, payload: dbPayload.errors })
      }
    })
    // dispatch({ type: DELETE_DESTINATION_SUCCESS, payload: data })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Deleting Destination`,
      },
      reduxType: DELETE_DESTINATION_FAIL,
    })
    // dispatch({ type: DELETE_DESTINATION_FAIL, payload: err.response?.data?.errors })
  }
}

///////////////////////////////////////////// BROKER /////////////////////////////////////////////

export const getBrokersAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_BROKERS_START })
  const model = 'Broker'
  const {
    cb,
    includeDeactivated,
    brokerId,
    contactPerson,
    name,
    phone,
    extcode,
    fax,
    email,
    zipcode,
    brokerById,
    location,
  } = payload
  try {
    const url = `${BASE_URL}/brokers/companies?${
      includeDeactivated ? `&includeDeactivated=true` : ''
    }${brokerId ? `&brokerId=${brokerId}` : ''}${
      contactPerson ? `&contactPerson=${contactPerson}` : ''
    }${name ? `&name=${name}` : ''}${phone ? `&phone=${phone}` : ''}${
      extcode ? `&extcode=${extcode}` : ''
    }${fax ? `&fax=${fax}` : ''}${email ? `&email=${email}` : ''}${
      zipcode ? `&zipcode${zipcode}` : ''
    }${brokerById ? `&brokerById=${brokerById}` : ''}${
      location ? `&location=${location}` : ''
    }`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const axPriv = await axiosPrivate()
    const { data = {} } = await axPriv.get(url)
    const { success, brokers } = data
    if (success) {
      dispatch({ type: GET_BROKERS_SUCCESS, payload: brokers })
      cb && cb({ brokers })
    } else {
      const err = 'Something went wrong, please try again later'
      handleErr({
        err,
        dispatch,
        customFunc: async () => {
          const data = await handleIDB.getAll({ model })
          console.log(err, data)
          const type = data ? GET_BROKERS_SUCCESS : GET_BROKERS_FAIL
          const payload = data || err.message || err.response?.data?.errors
          dispatch({ type, payload })
          cb && cb({ brokers: data || [] })
        },
      })
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        console.log(err, data)
        const type = data ? GET_BROKERS_SUCCESS : GET_BROKERS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb({ brokers: data || [] })
      },
    })
  }
}
//to app.js with socket
export const createBrokerAction = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_BROKER_START })
  try {
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createBroker`, payload)
    socket.once(`createdBroker${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        toast.success(`Created Succesfully`, { toastId: userInfo._id })
        cb && cb()
      } else {
        toast.error(`Error Creating`, { toastId: userInfo._id })
        dispatch({ type: CREATE_BROKER_FAIL, payload: errors })
      }
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating`,
      },
      reduxType: CREATE_BROKER_FAIL,
    })
  }
}
//to app.js with socket
export const updateBrokerAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_BROKER_START })
  try {
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateBroker`, payload)
    socket.once(`brokerUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        toast.success(`Updated Succesfully`, { toastId: userInfo._id })
        cb && cb()
      } else {
        toast.success(`Error Updating`, { toastId: userInfo._id })
        dispatch({ type: UPDATE_BROKER_FAIL, payload: errors })
      }
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating`,
      },
      reduxType: UPDATE_BROKER_FAIL,
    })
  }
}
//to app.js with socket
export const deactivateBrokerAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_BROKER_START })
  try {
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`toggleBroker`, payload)
    socket.once(`brokerToggled${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        cb && cb()
      } else {
        dispatch({ type: UPDATE_BROKER_FAIL, payload: errors })
      }
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error !!! Please try again later`,
      },
      reduxType: UPDATE_BROKER_FAIL,
    })
  }
}
//to app.js with socket
export const deleteBrokerAction = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_BROKER_START })
  try {
    const { userInfo, cb } = payload
    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`deleteBroker`, payload)
    socket.once(`brokerDeleted${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        cb && cb()
      } else {
        dispatch({ type: DELETE_BROKER_FAIL, payload: errors })
      }
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Deleting Broker`,
      },
      reduxType: DELETE_BROKER_FAIL,
    })
  }
}

// //////////////////////////////////////////// VEHICLE /////////////////////////////////////////////

// export const getCompanyVehicle = (payload) => async (dispatch) => {
//   dispatch({ type: GET_VEHICLE_START })

//   try {
//     const { id, driver } = payload
//     const axPriv = await axiosPrivate()
//     const { data } = await axPriv.get(`/vehicles/${id}/${driver}`)

//     dispatch({ type: GET_VEHICLE_SUCCESS, payload: data.vehicle })
//   } catch (err) {
//     console.log(err)
//     dispatch({ type: GET_VEHICLE_FAIL, payload: err.response?.data?.errors })
//   }
// }

export const getVehiclesAction = (payload) => async (dispatch) => {
  // dispatch({ type: GET_VEHICLES_START })
  const { cb, includeDeactivated, facility, vehicleId, driver, selectedCompany } = payload
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/vehicles/companies?${facility ? `&facility=${facility}` : ''}${
        includeDeactivated ? `&includeDeactivated=${includeDeactivated}` : ''
      }${selectedCompany ? `&selectedCompany=${selectedCompany}` : ''}${
        vehicleId ? `&vehicleId=${vehicleId}` : ''
      }${driver ? `&driver=${driver}` : ''}`,
      // `/vehicles/companies?includeDeactivated=${!!payload?.includeDeactivated}`,
    )
    const { success, vehicles } = data
    dispatch({ type: GET_VEHICLES_SUCCESS, payload: success ? vehicles : [] })
    cb && cb({ vehicles })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model: 'Vehicle' })
        const type = data ? GET_VEHICLES_SUCCESS : GET_VEHICLES_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb(data)
      },
    })
  }
}

//to app.js with socket
export const createVehicleAction = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_VEHICLE_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.post('/vehicles', payload.formState)
    // const { data } = await axPriv.post('/vehicles', payload.formState)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createVehicle`, payload)
    socket.once(`vehicleCreated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: CREATE_VEHICLE_SUCCESS,
        //   payload: { vehicle },
        // })
        toast.success(`Vehicle Created`, { toastId: userInfo._id })
        cb && cb({ success })
      } else {
        toast.error(`Error Creating Vehicle`, { toastId: userInfo._id })
        dispatch({ type: CREATE_VEHICLE_FAIL, payload: errors })
      }
    })

    // dispatch({ type: CREATE_VEHICLE_SUCCESS, payload: { vehicle: data.vehicle, msg: data.msg } })
    // if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating Vehicle`,
      },
      reduxType: CREATE_VEHICLE_FAIL,
    })
    // dispatch({ type: CREATE_VEHICLE_FAIL, payload: err.response?.data?.errors })
  }
}

//to app.js with socket
export const updateVehicleAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_VEHICLE_START })
  const { userInfo, cb } = payload
  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.patch('/vehicles/' + payload.id, payload.formState)
    // const { data } = await axPriv.patch('/vehicles/' + payload.id, payload.formState)

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateVehicle`, payload)
    socket.once(`vehicleUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      cb && cb({ success })
      if (!success) {
        handleErr({
          err: errors,
          dispatch,
          alert: true,
          reduxType: UPDATE_VEHICLE_FAIL,
        })
      }
    })
    // dispatch({ type: UPDATE_VEHICLE_SUCCESS, payload: { vehicle: data.vehicle, msg: data.msg } })
    // payload.cb && payload.cb()
  } catch (err) {
    cb && cb({})
    handleErr({
      err,
      dispatch,
      alert: true,
      reduxType: UPDATE_VEHICLE_FAIL,
    })
    // dispatch({ type: UPDATE_VEHICLE_FAIL, payload: err.response?.data?.errors })
  }
}

//to app.js with socket
export const deactivateVehicleAction = (payload) => async (dispatch) => {
  // dispatch({ type: DEACTIVATE_VEHICLE_START })
  dispatch({ type: UPDATE_VEHICLE_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.put('/vehicles/activation/' + payload.id)
    // const { data } = await axPriv.put('/vehicles/activation/' + payload.id)
    const { userInfo } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`toggleVehicle`, payload)
    socket.once(`vehicleToggled${userInfo._id}`, (dbPayload) => {
      const { success } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_VEHICLE_SUCCESS,
        //   payload: { vehicle },
        // })
        if (payload.cb) payload.cb()
      } else {
        dispatch({ type: UPDATE_VEHICLE_FAIL, payload: dbPayload.errors })
      }
    })
    // dispatch({ type: DEACTIVATE_VEHICLE_SUCCESS, payload: data })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Activating/Disabling Vehicle`,
      },
      reduxType: UPDATE_VEHICLE_FAIL,
    })
    // dispatch({ type: UPDATE_VEHICLE_FAIL, payload: err.response?.data?.errors })
  }
}

//to app.js with socket
export const deleteVehicleAction = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_VEHICLE_START })

  try {
    // const axPriv = await axiosPrivate()
    // const { data } = await axPriv.delete('/vehicles/' + payload.id)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`deleteVehicle`, payload)
    socket.once(`vehicleDeleted${userInfo._id}`, (dbPayload) => {
      const { success } = dbPayload
      if (success) {
        // dispatch({
        //   type: DELETE_VEHICLE_SUCCESS,
        //   payload: { vehicle },
        // })
        cb && cb()
      } else {
        dispatch({ type: DELETE_VEHICLE_FAIL, payload: dbPayload.errors })
      }
    })
    // dispatch({ type: DELETE_VEHICLE_SUCCESS, payload: data })
    // if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Deleting Vehicle`,
      },
      reduxType: DELETE_VEHICLE_FAIL,
    })
    // dispatch({ type: DELETE_VEHICLE_FAIL, payload: err.response?.data?.errors })
  }
}

export const getVinData = async (payload) => {
  // dispatch({ type: GET_VEHICLES_START })
  const { cb, vin } = payload
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/vehicles/vin?${vin ? `vin=${vin}` : ''}`,
      // `/vehicles/companies?includeDeactivated=${!!payload?.includeDeactivated}`,
    )
    cb && cb(data)
    return data
  } catch (err) {
    handleErr({ err })
  }
}

// export const getGPSData = async (payload) =>
//   new Promise((resolve) => {
//     try {
//       const { cb, id, vin, lastLocationOnly, userInfo } = payload
//       if (id && vin) {
//         if (!socket.connected && userInfo?._id) socket.connect()
//         socket?.emit(`getGPSLocation`, payload)
//         socket.once(`GPSres${userInfo?._id}`, (dbPayload) => {
//           const { success, data = {} } = dbPayload || {}
//           // console.log(success, data)
//           resolve(success ? data : {})
//         })
//         // cb && cb(data)
//         // return data
//       } else {
//         resolve({})
//         // handleErr({ alert: true, err: { message: 'Vehicle Id is missing, please try again later' } })
//         // return {}
//       }
//     } catch (err) {
//       handleErr({ err })
//     }
//   })
export const getGPSData = async (payload) => {
  const { cb, id, vin, gpsSerial, lastLocationOnly } = payload
  try {
    if (id && vin) {
      const axPriv = await axiosPrivate()
      const { data } = await axPriv.get(
        `/vehicles/gps?id=${id}&${vin ? `vin=${vin}` : ''}${
          gpsSerial ? `&gpsSerial=${gpsSerial}` : ''
        }${lastLocationOnly ? `&lastLocationOnly=${lastLocationOnly}` : ''}`,
      )
      cb && cb(data)
      return data
    } else {
      handleErr({ alert: true, err: { message: 'Vehicle Id is missing, please try again later' } })
      return {}
    }
  } catch (err) {
    handleErr({ err })
  }
}

///////////////////////INSPECTIONS///////////////

export const getInspection = (payload) => async (dispatch) => {
  // dispatch({ type: GET_VEHICLES_START })
  const { cb, id, constraint, vehicle, latest, selectedCompany } = payload
  const model = 'Inspection'
  try {
    // if (!id) return toast.error('Vehicle ID is missing')
    if (!constraint) return toast.error('Please Select Time Range')
    if (!vehicle) return toast.error('Vehicle is Missing')
    const idIDB = `${vehicle}_${constraint}`
    if (constraint === todayRange()) await handleIDB.delete({ model, _id: idIDB })
    const inspIDB = constraint !== todayRange() ? await handleIDB.getVal({ model, _id: idIDB }) : ''

    if (inspIDB) {
      cb && cb()
      return dispatch({ type: GET_INSPECTION_SUCCESS, payload: inspIDB })
    }
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/inspections/inspection?id=${id || ''}${constraint ? `&constraint=${constraint}` : ''}${
        vehicle ? `&vehicle=${vehicle}` : ''
      }${latest ? `&latest=${latest}` : ''}${
        selectedCompany ? `&selectedCompany=${selectedCompany}` : ''
      }`,
    )
    const { success, inspection } = data
    if (success) {
      const dataIDB = !isEmpty(inspection)
        ? inspection
        : { inspectionDate: constraint.split('_')[0], vehicle }
      if (isPast({ constraint1: dataIDB?.inspectionDate })) {
        handleIDB.put({ model, _id: idIDB, data: dataIDB })
      }
      // constraint !== todayRange() && handleIDB.put({ model, _id: idIDB, data: dataIDB })
      dispatch({ type: GET_INSPECTION_SUCCESS, payload: inspection })
      cb && cb()
    } else {
      // toast.error('Error Getting Inspection', { toastId: 'error' })
      dispatch({ type: GET_INSPECTION_FAIL, payload: {} })
    }
  } catch (err) {
    handleErr({ err, dispatch, reduxType: GET_INSPECTION_FAIL })
    // dispatch({ type: GET_INSPECTION_FAIL, payload: {} })
  }
}
export const getInspections = (payload) => async (dispatch) => {
  dispatch({ type: GET_INSPECTIONS_START })
  const model = 'Inspection'
  const { cb, constraint, vehicle, creator, editors, selectedCompany } = payload
  try {
    if (!constraint) return toast.error('Please Select Time Range')
    if (!vehicle) return toast.error('Vehicle is Missing')
    const idIDB = `${vehicle}_${constraint}`
    if (constraint === todayRange()) await handleIDB.delete({ model, _id: idIDB })
    if (constraint !== todayRange()) {
      const inspIDB = await handleIDB.getAll({ model })
      const payload = inspIDB?.length
        ? inspIDB.filter(
            (ins) =>
              isInTimeRange({ startTime: Date.parse(ins?.inspectionDate), constraint }) &&
              ins?.vehicle === vehicle,
          )
        : ''
      if (payload?.length) {
        cb && cb()
        return dispatch({ type: GET_INSPECTIONS_SUCCESS, payload })
      }
    }

    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/inspections?constraint=${constraint}${vehicle ? `&vehicle=${vehicle}` : ''}${
        creator ? `&creator=${creator}` : ''
      }${editors ? `&editors=${editors}` : ''}${
        selectedCompany ? `&selectedCompany=${selectedCompany}` : ''
      }`,
    )
    const { success, inspections = [] } = data || {}
    if (success) {
      if (constraint !== todayRange()) {
        // if (constraint !== todayRange() && isSameWeekDay(constraint)) {
        if (inspections?.length) {
          for (const ins of inspections) {
            if (isPast({ constraint1: ins?.inspectionDate })) {
              handleIDB.put({
                model,
                _id: `${ins?.vehicle}_${ins?.inspectionDate}`,
                data: ins,
              })
            }
          }
        } else {
          console.log(isPast({ constraint1: constraint.split('_')[0] }))
          if (isPast({ constraint1: constraint.split('_')[0] })) {
            handleIDB.put({
              model,
              _id: `${vehicle}_${constraint.split('_')[0]}`,
              data: {
                inspectionDate: constraint.split('_')[0],
                vehicle,
              },
            })
          }
        }
      }
      dispatch({ type: GET_INSPECTIONS_SUCCESS, payload: inspections })
      cb && cb()
    } else {
      // toast.error('Error Getting Inspections', { toastId: 'error' })
      dispatch({ type: GET_INSPECTIONS_FAIL, payload: [] })
    }
  } catch (err) {
    handleErr({ err, dispatch, reduxType: GET_INSPECTIONS_FAIL })
    // dispatch({ type: GET_INSPECTIONS_FAIL, payload: [] })
  }
}
//to app.js with socket
export const createInspection = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_INSPECTION_START })

  try {
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createInspection`, payload)
    socket.once(`inspectionCreated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: CREATE_VEHICLE_SUCCESS,
        //   payload: { vehicle },
        // })
        toast.success(`Inspection Created`, { toastId: userInfo._id })
        cb && cb()
      } else {
        toast.error(`Error Creating Inspection`, { toastId: userInfo._id })
        dispatch({ type: CREATE_INSPECTION_FAIL, payload: errors })
      }
    })

    // dispatch({ type: CREATE_VEHICLE_SUCCESS, payload: { vehicle: data.vehicle, msg: data.msg } })
    // if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating Inspection`,
      },
      reduxType: CREATE_VEHICLE_FAIL,
    })
    // dispatch({ type: CREATE_VEHICLE_FAIL, payload: err.response?.data?.errors })
  }
}
//to app.js with socket
export const updateInspection = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_INSPECTION_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.patch('/vehicles/' + payload.id, payload.formState)
    // const { data } = await axPriv.patch('/vehicles/' + payload.id, payload.formState)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateInspection`, payload)
    socket.once(`inspectionUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      toast.dismiss()
      setTimeout(() => {
        if (success) {
          toast.success(`Inspection Updated`, { toastId: userInfo._id })
          cb && cb()
        } else {
          handleErr({
            err: errors,
            dispatch,
            alert: true,
            reduxType: UPDATE_INSPECTION_FAIL,
          })
        }
      }, 707)
    })
    // dispatch({ type: UPDATE_VEHICLE_SUCCESS, payload: { vehicle: data.vehicle, msg: data.msg } })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating Inspection`,
      },
      reduxType: UPDATE_INSPECTION_FAIL,
    })
    // dispatch({ type: UPDATE_INSPECTION_FAIL, payload: err.response?.data?.errors })
  }
}

// COUPONS

export const createCoupon = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_COUPON_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.post('/coupons', payload.formState)

    dispatch({ type: CREATE_COUPON_SUCCESS, payload: data.coupon })

    if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Creating Coupon`,
      },
      reduxType: CREATE_COUPON_FAIL,
    })
    // dispatch({ type: CREATE_COUPON_FAIL, payload: err.response?.data?.errors })
  }
}

export const updateCoupon = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_COUPON_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.put('/coupons/' + payload.id, payload.formData)

    dispatch({ type: UPDATE_COUPON_SUCCESS, payload: { coupon: data.coupon, msg: data.msg } })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating Coupon`,
      },
      reduxType: UPDATE_COUPON_FAIL,
    })
    // dispatch({ type: UPDATE_COUPON_FAIL, payload: err.response?.data?.errors })
  }
}

export const getCoupon = (payload) => async (dispatch) => {
  dispatch({ type: GET_COUPON_START })
  try {
    const { id, name, cb } = payload
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/coupons/coupon/${id ? `?id=${id}` : ''}${name ? `?name=${name}` : ''}`,
    )
    const { coupon } = data
    coupon.validity = coupon.validity.slice(0, coupon.validity.indexOf('T'))
    // console.log(data.coupon.validity)
    cb && cb({ coupon })
    dispatch({ type: GET_COUPON_SUCCESS, payload: coupon })
  } catch (err) {
    handleErr({ err, alert: true, dispatch, reduxType: GET_COUPON_FAIL })
    // dispatch({ type: GET_COUPON_FAIL, payload: err.response?.data?.errors })
  }
}

export const getCoupons = (payload) => async (dispatch) => {
  dispatch({ type: GET_COUPONS_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(`/coupons/`)

    dispatch({ type: GET_COUPONS_SUCCESS, payload: data.coupons })
  } catch (err) {
    handleErr({ err, dispatch, reduxType: GET_COUPONS_FAIL })
    // dispatch({ type: GET_COUPONS_FAIL, payload: err.response?.data?.errors })
  }
}

export const deleteCoupon = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_COUPON_START })
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.delete('/coupons/' + payload.id)

    dispatch({ type: DELETE_COUPON_SUCCESS, payload: data })

    payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Deleting Coupon`,
      },
      reduxType: DELETE_COUPON_FAIL,
    })
    // dispatch({ type: DELETE_COUPON_FAIL, payload: err.response?.data?.errors })
  }
}

// PAYMENTS

export const listTransactions = (payload) => async (dispatch) => {
  dispatch({ type: GET_TRANSACTIONS_START })

  try {
    const { company, state, constraint, amount, email } = payload || {}
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/transactions?${company ? `&company=${company}` : ''}${state ? `&state=${state}` : ''}${
        constraint ? `&constraint=${constraint}` : ''
      }${amount ? `&amount=${amount}` : ''}${email ? `&email=${email}` : ''}`,
    )

    dispatch({ type: GET_TRANSACTIONS_SUCCESS, payload: data.transactions })
  } catch (err) {
    handleErr({ err, dispatch, reduxType: GET_TRANSACTIONS_FAIL })
    // dispatch({ type: GET_TRANSACTIONS_FAIL, payload: err.response?.data?.errors })
  }
}

export const deleteTransaction = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_TRANSACTION_START })
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.delete('/transactions/' + payload.id)
    // if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')
    // if (data?.success) payload.cb()
    payload.cb()
    dispatch({ type: DELETE_TRANSACTION_SUCCESS, payload: data })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Deleting Transantion`,
      },
      reduxType: DELETE_TRANSACTION_FAIL,
    })
    // dispatch({ type: DELETE_TRANSACTION_FAIL, payload: err.response?.data?.errors })
  }
}

export const listBillingAgreement = (payload) => async (dispatch) => {
  dispatch({ type: GET_BILLING_AGREEMENT_START })
  if (!payload.id) return
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(`/payment/getagreement/${payload.id}`)
    dispatch({ type: GET_BILLING_AGREEMENT_SUCCESS, payload: data.agreements })
  } catch (err) {
    handleErr({ err, dispatch, reduxType: GET_BILLING_AGREEMENT_FAIL })
    // dispatch({ type: GET_BILLING_AGREEMENT_FAIL, payload: err.response?.data?.errors })
  }
}

export const cancelBillingAgreement = (payload) => async (dispatch) => {
  dispatch({ type: CANCEL_BILLING_AGREEMENT_START })
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(
      `/payment/cancelagreement/${payload.id}/${payload.selectedCompany}`,
    )
    dispatch({ type: CANCEL_BILLING_AGREEMENT_SUCCESS, payload: data.agreement })
    toast.success(data.msg)
    if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: CANCEL_BILLING_AGREEMENT_FAIL,
    })
    // dispatch({ type: CANCEL_BILLING_AGREEMENT_FAIL, payload: err.response?.data?.errors })
  }
}

export const updateBillingAgreement = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_BILLING_AGREEMENT_START })
  const {
    id,
    address,
    city,
    state,
    zipcode,
    country,
    company,
    coupon,
    couponApplied,
    plan,
    price,
    cb,
    ...rest
  } = payload
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.post(
      `/payment/updateagreement?id=${id}&company=${company}&plan=${plan}&price=${price}&address=${address}&city=${city}&state=${state}&zipcode=${zipcode}&country=${country}${
        coupon ? `&coupon=${coupon}` : ''
      }${couponApplied ? `&couponApplied=${couponApplied}` : ''}`,
      { ...rest },
    )
    const { success, url, agreement, msg } = data

    if (success) {
      dispatch({ type: UPDATE_BILLING_AGREEMENT_SUCCESS, payload: agreement })
      toast.success(msg)
    }
    window.location.href = url
    cb && cb({})
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: UPDATE_BILLING_AGREEMENT_FAIL,
      customFunc: () => cb && cb({ error: true }),
    })
    // dispatch({ type: UPDATE_BILLING_AGREEMENT_FAIL, payload: err.response?.data?.errors })
  }
}

// export const updatePlanPricing = (payload) => async (dispatch) => {
//   dispatch({ type: UPDATE_AGREEMENT_PRICING_START })
//   const { agreementId, value, cb } = payload
//   try {
//     const axPriv = await axiosPrivate()
//     const { data } = await axPriv.post(
//       `/payment/updatepricing?agreementId=${agreementId}&value=${value}`,
//     )

//     if (data.url) window.location.href = data.url

//     dispatch({ type: UPDATE_AGREEMENT_PRICING_SUCCESS, payload: data.agreement })
//     toast.success(data.msg)

//     if (cb) cb()
//   } catch (err) {
//     console.log(err)
//     if (cb) cb()
//     dispatch({ type: UPDATE_AGREEMENT_PRICING_FAIL, payload: err.response?.data?.errors })
//     toast.error(err.response?.data?.errors && err.response?.data?.errors[0])
//   }
// }

/////////////////////////COMPANIES//////////////////////////////////

export const getAllCompanies = (payload) => async (dispatch) => {
  dispatch({ type: GET_ALL_COMPANIES_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get('/companies')

    dispatch({ type: GET_ALL_COMPANIES_SUCCESS, payload: data.companies })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: GET_ALL_COMPANIES_FAIL,
    })
    // dispatch({ type: GET_ALL_COMPANIES_FAIL, payload: err.response?.data?.errors })
  }
}

export const getCompanyById = (payload) => async (dispatch) => {
  dispatch({ type: GET_COMPANY_START })
  try {
    const { id, cb } = payload
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(`/companies/byid/${id}`)
    const { company } = data || {}
    dispatch({ type: GET_COMPANY_SUCCESS, payload: data.company })
    cb && cb({ company })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: GET_COMPANY_FAIL,
    })
    // dispatch({ type: GET_COMPANY_FAIL, payload: err.response?.data?.errors })
  }
}

export const getCompanyByDomain = (payload) => async (dispatch) => {
  dispatch({ type: GET_COMPANY_START })
  try {
    const { data } = await axiosPublic.get(`/companies/bydomain/${payload.domain}`)
    dispatch({ type: GET_COMPANY_SUCCESS, payload: data?.company })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      reduxType: GET_COMPANY_SUCCESS,
      payload: { name: 'XPERDRIVER', logoSrc: `/xperdriverlogo.png` },
    })
    // dispatch({
    //   type: GET_COMPANY_SUCCESS,
    //   payload: { name: 'XPERDRIVER', logoSrc: `/xperdriverlogo.png` },
    // })
    // dispatch({ type: GET_COMPANY_FAIL, payload: err.response?.data?.errors })
  }
}

export const getCompanySettings = (payload) => async (dispatch) => {
  dispatch({ type: GET_COMPANY_SETTINGS_START })
  try {
    const { id, cb } = payload
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(`/companies/companysettings/${id}`)
    dispatch({ type: GET_COMPANY_SETTINGS_SUCCESS, payload: data })
    cb && cb(data)
  } catch (err) {
    handleErr({
      err,
      dispatch,
      reduxType: GET_COMPANY_SETTINGS_FAIL,
    })
    // toast.error(`Error getting company settings`, { toastId: 'error' })
    // console.log(err)
    // dispatch({ type: GET_COMPANY_SETTINGS_FAIL, payload: err?.response?.data?.errors })
  }
}

export const toggleCompany = (payload) => async (dispatch) => {
  dispatch({ type: DEACTIVATE_COMPANY_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.put('/companies/togglecompany/' + payload.id)

    dispatch({ type: DEACTIVATE_COMPANY_SUCCESS, payload: data })
    if (payload.cb) payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: DEACTIVATE_COMPANY_FAIL,
    })
    // dispatch({ type: DEACTIVATE_COMPANY_FAIL, payload: err.response?.data?.errors })
  }
}

export const updateCompany = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_COMPANY_START })
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.put('/companies/' + payload.id, payload.formData)

    dispatch({
      type: UPDATE_COMPANY_SUCCESS,
      payload: { company: data.company, msg: data.msg },
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Updating Company` },
      reduxType: UPDATE_COMPANY_FAIL,
    })
    // dispatch({ type: UPDATE_COMPANY_FAIL, payload: err.response?.data?.errors })
  }
}

//to app.js with socket
export const toggleCompanySettings = (payload) => async (dispatch) => {
  // dispatch({ type: UPDATE_COMPANY_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.patch('/vehicles/' + payload.id, payload.formState)
    // const { data } = await axPriv.patch('/vehicles/' + payload.id, payload.formState)
    const { userId, cb } = payload

    if (!socket.connected && userId) socket.connect()
    socket.emit(`toggleCompanySettings`, payload)
    socket.once(`companySettingsToggled${userId}`, (dbPayload) => {
      const { success } = dbPayload
      cb && cb({ success })
      // if (success) {
      // dispatch({
      //   type: UPDATE_COMPANY_SUCCESS,
      //   payload: { company },
      // })
      // } else {
      // dispatch({ type: UPDATE_COMPANY_SETTINGS_FAIL, payload: dbPayload.errors })
      // }
    })
    // dispatch({ type: UPDATE_VEHICLE_SUCCESS, payload: { vehicle: data.vehicle, msg: data.msg } })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: UPDATE_COMPANY_FAIL,
    })
    // dispatch({ type: UPDATE_COMPANY_FAIL, payload: err.response?.data?.errors })
  }
}

export const deleteCompany = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_COMPANY_START })

  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.delete('/companies/' + payload.id)

    dispatch({ type: DELETE_COMPANY_SUCCESS, payload: data })
    payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {},
      reduxType: DELETE_COMPANY_FAIL,
    })
    // dispatch({ type: DELETE_COMPANY_FAIL, payload: err.response?.data?.errors })
  }
}

///////////////////////////////////////////// PRODUCT /////////////////////////////////////////////

export const getCompanyProducts = (payload) => async (dispatch) => {
  dispatch({ type: GET_PRODUCTS_START })
  const model = 'Product'
  const { cb, includeDeactivated, name, id } = payload
  try {
    const url = `${BASE_URL}/products/companies?${
      includeDeactivated ? `includeDeactivated=true` : ''
    }${name ? `&${name}` : ''}${id ? `&${id}` : ''}`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(url)
    if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

    dispatch({ type: GET_PRODUCTS_SUCCESS, payload: data.products })
    cb && cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        console.log(err, data)
        const type = data ? GET_PRODUCTS_SUCCESS : GET_PRODUCTS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb()
      },
    })
  }
}

export const getCustomerProducts = (payload) => async (dispatch) => {
  const { userId, cb } = payload
  dispatch({ type: GET_CUSTOMER_PRODUCTS_START })
  const model = 'Product'
  try {
    const url = `${BASE_URL}/products/customer/${userId}`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(url)
    if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

    dispatch({ type: GET_CUSTOMER_PRODUCTS_SUCCESS, payload: data.customerProducts })
    cb && cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        console.log(err, data)
        const type = data ? GET_CUSTOMER_PRODUCTS_SUCCESS : GET_CUSTOMER_PRODUCTS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb()
      },
    })
  }
}

export const createCompanyProduct = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PRODUCTS_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.post('/destinations', formData)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`createProduct`, payload)
    socket.once(`createdProduct${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: CREATE_DESTINATIONS_SUCCESS,
        //   payload: { destination: dbPayload.destination },
        // })
        toast.success(`Created Succesfully`, { toastId: userInfo._id })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        toast.error(`Error Creating`, { toastId: userInfo._id })
        dispatch({ type: CREATE_PRODUCTS_FAIL, payload: errors })
      }
    })
    // const { data } = await axPriv.post('/destinations', formData)
    // dispatch({ type: CREATE_DESTINATIONS_SUCCESS, payload: data })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Creating Product!` },
      reduxType: CREATE_PRODUCTS_FAIL,
    })
    // dispatch({ type: CREATE_PRODUCTS_FAIL, payload: err.response?.data?.errors })
  }
}

export const updateCompanyProduct = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.put('/destinations/' + payload.id, payload.formState)
    // const { data } = await axPriv.put('/destinations/' + payload.id, payload.formState)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateProduct`, payload)
    socket.once(`productUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_DESTINATION_SUCCESS,
        //   payload: { destination: dbPayload.destination },
        // })
        toast.success(`Updated Succesfully`, { toastId: userInfo._id })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        toast.error(`Error Updating`, { toastId: userInfo._id })
        dispatch({ type: UPDATE_PRODUCT_FAIL, payload: errors })
      }
    })
    // dispatch({
    //   type: UPDATE_DESTINATION_SUCCESS,
    //   payload: { destination: data.destination, msg: data.msg },
    // })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Updating Product!` },
      reduxType: UPDATE_PRODUCT_FAIL,
    })
    // dispatch({ type: UPDATE_PRODUCT_FAIL, payload: err.response?.data?.errors })
  }
}

export const deactivateCompanyProduct = (payload) => async (dispatch) => {
  // dispatch({ type: DEACTIVATE_DESTINATION_START })
  dispatch({ type: UPDATE_DESTINATION_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.put('/destinations/activation/' + payload.id)
    // const { data } = await axPriv.put('/destinations/activation/' + payload.id)
    const { userInfo } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`toggleProduct`, payload)
    socket.once(`productToggled${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      if (success) {
        // dispatch({
        //   type: UPDATE_DESTINATION_SUCCESS,
        //   payload: { destination: dbPayload.destination },
        // })
        if (payload.cb) payload.cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        dispatch({ type: UPDATE_PRODUCT_FAIL, payload: errors })
      }
    })
    // dispatch({ type: DEACTIVATE_DESTINATION_SUCCESS, payload: data })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Activating/Disabling Product!` },
      reduxType: UPDATE_PRODUCT_FAIL,
    })
    // dispatch({ type: UPDATE_PRODUCT_FAIL, payload: err.response?.data?.errors })
  }
}

export const deleteCompanyProduct = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_START })

  try {
    // const axPriv = await axiosPrivate()
    // await axPriv.delete('/destinations/' + payload.id)
    // const { data } = await axPriv.delete('/destinations/' + payload.id)
    const { userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`deleteProduct`, payload)
    socket.once(`productDeleted${userInfo._id}`, (dbPayload) => {
      const { success } = dbPayload
      if (success) {
        // dispatch({
        //   type: DELETE_DESTINATION_SUCCESS,
        //   payload: { destination },
        // })
        cb && cb()
        // if (payload.cb) payload.cb(dbPayload?.user?._id)
      } else {
        dispatch({ type: DELETE_PRODUCT_FAIL, payload: dbPayload.errors })
      }
    })
    // dispatch({ type: DELETE_DESTINATION_SUCCESS, payload: data })
    // payload.cb && payload.cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Deleting Product!` },
      reduxType: DELETE_PRODUCT_FAIL,
    })
    // dispatch({ type: DELETE_PRODUCT_FAIL, payload: err.response?.data?.errors })
  }
}

//////////////// PRODUCTS LOGS

export const getCompanyProductLogs = (payload) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_LOGS_START })
  const model = 'Product'
  const { cb, name, id, constraint } = payload
  try {
    const url = `${BASE_URL}/products/logs/companies?${
      constraint ? `constraint=${constraint}` : ''
    }${name ? `&name=${name}` : ''}${id ? `&id=${id}` : ''}`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(url)
    if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

    dispatch({ type: GET_PRODUCT_LOGS_SUCCESS, payload: data.productLogs })
    cb && cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        console.log(err, data)
        const type = data ? GET_PRODUCT_LOGS_SUCCESS : GET_PRODUCT_LOGS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb()
      },
    })
  }
}

export const getVehicleProducts = (payload) => async (dispatch) => {
  const { vehicleId, cb } = payload
  dispatch({ type: GET_VEHICLE_PRODUCTS_START })
  const model = 'Product'
  try {
    const url = `${BASE_URL}/products/vehicle/${vehicleId}`.trim()

    // const res = await fetchPrivate({ url })
    // const data = await res.json()
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(url)
    if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')

    dispatch({ type: GET_VEHICLE_PRODUCTS_SUCCESS, payload: data.vehicleProducts })
    cb && cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      customFunc: async () => {
        const data = await handleIDB.getAll({ model })
        console.log(err, data)
        const type = data ? GET_VEHICLE_PRODUCTS_SUCCESS : GET_VEHICLE_PRODUCTS_FAIL
        const payload = data || err.message || err.response?.data?.errors
        dispatch({ type, payload })
        cb && cb()
      },
    })
  }
}
