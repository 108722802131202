import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import CompanyLoader from './CompanyLoader'
import handleErr from 'src/utils/handle-error'

const FullLoader = ({ progressBar, cb, showCloseDelay = 7777, loadingMsg, autoClose }) => {
  const [showClose, setShowClose] = useState(null)
  const [showCancel, setShowCancel] = useState(false)
  const isMounted = useRef()

  useEffect(() => {
    isMounted.current = true
    // const fLoader = document.querySelector('.full-loader')
    // fLoader?.classList?.add('show-easy')
    // setTimeout(() => {
    //   fLoader?.classList?.remove('opacity0')
    // }, 7)
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    try {
      if (cb) {
        if (autoClose) {
          setTimeout(() => {
            isMounted.current && cb()?.func && cb()?.func()
          }, showCloseDelay)
        } else {
          setTimeout(() => {
            isMounted.current &&
              setShowClose(
                <button
                  className="fixed-top btn btn-close shadow-danger p-3"
                  style={{ top: '3px', left: '3px' }}
                  aria-label="Close"
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault()
                    cb()?.func && cb()?.func()
                  }}
                ></button>,
              )
            isMounted.current && setShowCancel(true)
          }, showCloseDelay)
        }
      }
    } catch (err) {
      handleErr({ err })
    }
    return () => {}
  }, [showClose, cb, showCloseDelay, autoClose])

  return (
    <div className="full-loader">
      {showClose}
      <CompanyLoader
        progressBar={progressBar}
        loadingMsg={loadingMsg}
        showCancel={showCancel}
        cb={cb}
      />
    </div>
  )
}

FullLoader.propTypes = {
  showCloseDelay: PropTypes.number,
  progressBar: PropTypes.any,
  loadingMsg: PropTypes.any,
  cb: PropTypes.any,
  autoClose: PropTypes.bool,
}

export default React.memo(FullLoader)
